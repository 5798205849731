
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.ClassicsExtensionPageConatiner-openThroughNavbar-heading{
    text-align: center;
    font-size: 28px;
    color: white;
    background-color: black;
    font-family: "Montserrat", sans-serif;
    height: 70px;
  padding-top: 15px;
    font-weight: 50;
  }
  .ClassicsExtensionPageConatiner-openThroughNavbar-ClassicsByAnnosuneHeading{
   
    /* font-family: Retina,Arial,Helvetica,sans-serif; */
    font-family: 'Montserrat',sans-serif;

    font-weight: 700 ;
   position: relative;
   /* right: 350px; */
   width: 100%;
   color: black; 
   font-size: 44px;
   top: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   /* font-family: 'Bebas Neue', cursive; */
text-transform: uppercase;
/* text-shadow: 4px 4px 8px #4baeec85; */
    position: relative;
    top: 70px;
    padding-bottom: 10px;
}
.ClassicsByannosuneContainer-forClassicsEyelashExtensionpage{
    height: 444px;
margin-top: 100px;
}
.backgroundColorLineFor-ClassicsExtensionPageConatiner{
    background-color:  #EFD3D2;
    height: 5px;
    margin-top: 60px;
}
.ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage{
  font-size: 14px;
/* font-family: '', serif; */
font-family: 'Montserrat',sans-serif;
width: 90%;
margin-left: 50px;
margin-top: 20px;
text-align: center;
}
.classicExtensionsPageMobileNavbar{
    display: none;
}
@media (max-width:1023px) {
    .ClassicsExtensionPageConatiner-openThroughNavbar-heading{
        font-size: 24px;
        height: 62px;
    }
    .classicExtensionPageNavbar{
        display: none;
    }
    .classicExtensionsPageMobileNavbar{
        display: block;
        position: static;
        width: 100%;
    }
    .ClassicsExtensionPageConatiner-openThroughNavbar-ClassicsByAnnosuneHeading{
        font-size: 30px;
        text-align: center;
        top: 10px;
    }
    .ClassicsByannosuneContainer-forClassicsEyelashExtensionpage{
        height: auto;
        margin-top: 0px;
    }
    .responsiveSwiperForClassicsExtensionpage{
        height: 945px;
        width:  auto;
    }
    .backgroundColorLineFor-ClassicsExtensionPageConatiner{
        margin-top: 0px;
    }
    .ClasiicLashFullLiftAnnosuneImg{
        position: relative;
        bottom: 70px;
        margin-bottom: 0px;

    }
    .ClasiicLashFullLiftAnnosuneImg80{
    height: 300px;
    width: 300px;
    float: left;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    position: relative;
    bottom: 50px;
    }
    .ClasiicLashFullLiftAnnosuneInfo{
        position: relative;
        bottom: 40px;
    }
   .ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage{
    margin-top: 0px;
    width: 70%;
    margin-left: 59px;
   }
   .ClasiicLashFullLiftAnnosuneInfo80{
    position: relative;
        bottom: 20px;
   }
}