*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.mainFlexEyeBrowServiceOfHomePageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 100%;
    background-color: whitesmoke; 
}

.mainflexEyeBrowServiceOfHomePageContainerHeading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 100px;
}

.mainflexEyeBrowServiceOfHomePageContainerHeading1{
    width: 500px;
    height: 100px;
    font-size: 80px;
    font-family: 'Bebas Neue', cursive;
    font-weight: 900;
    
      text-shadow: 4px 4px 8px rgb(17, 204, 233);;  
   
color: black;
cursor: pointer;
}

.mainflexEyeBrowServiceOfHomePageContainerHeading2{
    font-size: 28px;
    width: 600px;
    font-family: 'Bebas Neue', cursive;
    padding-right: 30px;
    text-shadow: 4px 4px 8px rgb(17, 204, 233);;  
   padding-left: 60px;
    color: black;
    font-weight: 900;

}

.mainflexEyeBrowServiceOfHomePageContainerHeading3{
    font-size: 28px;
    width: 450px;
    font-family: 'Bebas Neue', cursive;
    padding-left: 30px;
    text-shadow: 4px 4px 8px rgb(17, 204, 233);;  
   padding-right: 0px;
    color: black;
    font-weight: 900;
}

.mainflexEyeBrowContainerServiceOfHomePageHeading2ndPart{
    font-size: 28px;
    font-family: 'Bebas Neue', cursive;
    padding-right: 30px;
    /* color: whitesmoke; */

    font-weight: 900;
    text-shadow: 4px 4px 8px rgb(17, 204, 233);;  
   
    color: black;
}

.mainflexEyeBrowContainerServiceOfHomePageHeading5{
    font-size: 28px;
    width: 500px;
    font-family: 'Bebas Neue', cursive;
    padding-left: 150px;
    text-shadow: 4px 4px 8px rgb(17, 204, 233);;  
   
    color: black;
}

.rightImgEyeBrowServiceOfHomePageFlexContainer{
    height: 500px;
    width: 550px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    margin-left: 100px;
}