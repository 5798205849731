*{
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
    
}
.NavbarConatinerOfKeratinlashLiftpage{
position: sticky;
top: 0;
z-index: 100;

}
.mobileNavbarRemoval{
    display: none;
}
.KeratinlashLiftPageMobileNavbar{
    display: none;
}
.KeratinLashLiftServiceMainContainer{
    /* background-image:linear-gradient(to left bottom, #ca92b5, #be9cce, #a7a8e4, #86b6f1, #5ec3f4, #51c2f2, #41c0f0, #2cbfee, #47b0ee, #669fe7, #828dd9, #987ac4); ; */
    /* height: 640px; */
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* margin-top: 200px; */
}


.KeratinLashLiftServiceSection{
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 900px; */
    padding-top: 25px;
    margin-bottom: 10px;
}

.KeratinLashLiftServiceimage{
    height: 300px;
    width: 300px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}
.HeadingOfKeratinlashLiftPage{
font-size: 28px;
/* font-family: '', serif; */
font-family: 'Montserrat',sans-serif;
color:white;
background-color: black;
width: 100%;
text-align: center;
height: 54px;
    padding-top: 10px;

}
.KeratinLashLiftServiceHeading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;

    margin-bottom: 10px;
}
.KeratinEyelashLiftPrice{

}
.KeratinLashLiftServiceSectionHeadingInfo{
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 10px;
    /* font-weight: 900; */
    color: black;
    /* text-shadow: 4px 4px 8px  #ef7c8e66;; */
    padding-left: 60px;
}
.bookNowButtonForKeratinLashLiftServicePage{
margin-left: 50px;
}
.KeratinLashLiftServiceSectionInfo{
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
font-weight: 100;
    width: 400px;
    margin-left: 100px; 
    color: black;

}
.InleiLashListServiceSectionForGivingMargin{
    margin-bottom: 22px;
}

@media (max-width:1023px) {
    .classicLashCertificationCourseSection{
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .classicLashCertificationCourseimage{
        width: 300px;
        height: auto;
        margin-top: 10px;
    }
    .classicLashCertificationCourseSectionHeadingInfo{
        text-align: center;
        font-size: 28px;
        padding-left: 0px;
        width: 80%;
        margin-top: 10px;
    }
    .classicLashCertificationCourseSectionInfo{
font-size: 14px;
width: 84%;
margin-left: 0px;
text-align: center;
    }
    .HeadingOfKeratinlashLiftPage{
        /* margin-top: 20px; */
    }
.KeratinLashLiftServiceSection{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.KeratinEyelashLiftPrice{
    margin-top: 20px;
}
.KeratinLashLiftServiceSectionHeadingInfo{
    font-size: 22px;
    padding-left: 0px;
    margin-top: 10px;
}
.KeratinLashLiftServiceSectionInfo{
    margin-left: 0px;
    width: 90%;
    text-align: center;
}
.bookNowButtonForKeratinLashLiftServicePage{
    margin-left: 0px;
}
.mobileNavbarRemoval{
    display: block;
}
.NavbarConatinerOfKeratinlashLiftpage{
    display: none;
}
.KeratinlashLiftPageMobileNavbar{
    display: block;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 100;
}
.mobileNavbarRemoval{
    display: block;
    width: 100%;
    position: static;
    top: 0;
    z-index: 100;
}
.NavbarConatinerOfKeratinlashLiftpage{
    display: none;
}
.HeadingOfKeratinlashLiftPage{
    font-size: 22px;
}
}