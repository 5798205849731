
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.MegaVolumeExtensionPageConatiner-openThroughNavbar-heading{
    text-align: center;
    font-size: 28px;
    color: white;
    background-color: black;
    font-family: "Montserrat", sans-serif;
    height: 70px;
  padding-top: 15px;
    font-weight: 50;
  }
  .MegaVolumeExtensionPageConatiner-openThroughNavbar-MegaVolumeByAnnosuneHeading{
   
    /* font-family: Retina,Arial,Helvetica,sans-serif; */
    font-family: 'Montserrat',sans-serif;

    font-weight: 700 ;
   position: relative;
   /* right: 350px; */
   width: 100%;
   color: black; 
   font-size: 44px;
   top: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   /* font-family: 'Bebas Neue', cursive; */
text-transform: uppercase;
/* text-shadow: 4px 4px 8px #4baeec85; */
    position: relative;
    top: 70px;
    padding-bottom: 10px;
}
.HybridsByannosuneContainer-forMegaVolumeEyelashExtensionpage{
    height: 444px;
margin-top: 100px;
}
.backgroundColorLineFor-MegaVolumeExtensionPageConatiner{
    background-color:  #EFD3D2;
    height: 5px;
    margin-top: 60px;
}

@media (max-width:1023px) {
    .MegaVolumeExtensionPageConatiner-openThroughNavbar-heading{
        font-size: 24px;
        height: 90px;
    }
    .MegaVolumeExtensionPageConatiner-openThroughNavbar-MegaVolumeByAnnosuneHeading{
        font-size: 30px;
        text-align: center;
    }
    .HybridsByannosuneContainer-forMegaVolumeEyelashExtensionpage{
        height: auto;
    }
    .responsiveSwiperForMegaVolumeExtensionpage{
        height: 945px;
        width:  auto;
    }
    .backgroundColorLineFor-MegaVolumeExtensionPageConatiner{
        margin-top: 0px;
    }
}