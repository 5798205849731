.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    width: 500px;
    height: auto;
  }
.KeratinLashLiftForNavbarMainContainer{
    height: 313px;
    /* background-color: black; */
    width: 1166px;
    position: relative;
    right: 50px;
    bottom: 16px;
  background-color: #67595E;
    
  

}
.KeratinLashLiftForNavbarMainContainer{
  height: 313px;
  /* background-color: black; */
  width: 1034px;
  position: relative;
  right: 50px;
  bottom: 16px;
background-color: #67595E;
  


}
.KeratinLashLiftForNavbarMainContainerBrowsBykatelyn{
  height: 400px;
  /* background-color: black; */
  width: 1034px;
  position: relative;
  right: -6px;
  bottom: 16px;
  background-color: #67595E;
}
.KeratinLashLiftForNavbarMainContainerMicroblading{
  height: 350px;
  /* background-color: black; */
  width: 1034px;
  position: relative;
  right: -17px;
  bottom: 16px;
  background-color: #67595E;
}
.KeratinLashLiftForNavbarMainContainerMegavolume{
  height: 313px;
    /* background-color: black; */
    width: 1034px;
    position: relative;
    right: 50px;
    bottom: 10px;
    background-color: #67595E;
}
.KeratinLashLiftForNavbarMainContainerVolume{
  height: 313px;
    /* background-color: black; */
    width: 1034px;
    position: relative;
    right: 50px;
    bottom: 16px;
  background-color: #67595E;
    
}
.KeratinLashLiftForNavbarMainContainerHybrids{
  height: 313px;
    /* background-color: black; */
    width: 1034px;
    position: relative;
    right: 12px;
    bottom: 59px;
  background-color: #67595E;
    
}
.KeratinLashLiftForNavbarMainContainerForNavbar{
  height: 313px;
  /* background-color: black; */
  width: 1034px;
  position: relative;
  right: -179px;
  /* bottom: 210px; */
  background-color: #67595E;
  
}
.KeratinLashLiftForNavbarConatinerClassics{
  height: 355px;
  /* background-color: black; */
  width: 100%;
  position: relative;
  right: 0px;
  bottom: 0px;
  /* top: 38px; */
  background-color:  #67595E;

}
.KeratinLashLiftForNavbarMainContainerClassics{
  height: 334px;
  /* background-color: black; */
  width: 1034px;
  position: relative;
  right: -90px;
  bottom: 264px;
  background-color:  #67595E;
  
}
.KeratinLashLiftForNavbarMainContainerArtists{
  height: 363px;
  /* background-color: black; */
  width: 1034px;
  position: relative;
  right: 50px;
  bottom: 16px;
  background-color:  #67595E;
  
}
.KeratinLashLiftForNavbarMainContainerArtistsKatelyn{
  height: 363px;
  /* background-color: black; */
  width: 1166px;
  position: relative;
  right: 50px;
  bottom: 96px;
  background-color:  #67595E;

}
.KeratinLashLiftForNavbarMainContainerArtistsJackie{
  height: 362px;
    /* background-color: black; */
    width: 1034px;
    position: relative;
    right: 50px;
    bottom: 50px;
    background-color: #67595E;
}
.KeratinLashLiftForNavbarMainContainerArtistsShelly{
  height: 363px;
    /* background-color: black; */
    width: 1166px;
    position: relative;
    right: 50px;
    bottom: 127px;
    background-color:  #67595E;
}
.KeratinLashLiftForNavbarMainContainerArtistsHappy{
  height: 363px;
  /* background-color: black; */
  width: 1166px;
  position: relative;
  right: 50px;
  bottom: 165px;
  background-color:  #67595E;
}
.KeratinLashLiftForNavbarMainContainerCourses{
  height: 313px;
  /* background-color: black; */
  width: 1034px;
  position: relative;
  right: -69px;
  bottom: -54px;
  background-color: #67595E
}
.forBackGroundColorInLAshLift{
  background-color: #67595E
  

}
.KeratinLashLiftForNavbarMainContainerCoursesLashLift{
  height: 335px;
  /* background-color: black; */
  width: 1034px;
  position: relative;
  right: 50px;
  bottom: 43px;
  background-color: #67595E;
}
  .KeratinLashLiftForNavbarSwiper{
    height: 500px;
    margin-top: 20px;
    width: 500px;
    /* margin-bottom: 100px; */
  }
  
  .KeratinLashLiftForNavbarHeadingforMicroblading-AligingIncenter{
    font-size: 24px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;
    width: 100%;
   text-align: center;
    color: white;
    margin-left: -7px;
    padding-bottom: 20px;
  }
  .KeratinLashLiftForNavbarHeadingKrtainLashLift{
    font-size: 24px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;
    width: 100%;
    /* margin-left: 251px; */
    color: white;
    text-align: center;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .classForBackgroundColor{
    background-color:  #EFD3D2;

  }
  .KeratinLashLiftForNavbarInfo{
    padding-bottom: 40px;
padding-left: 251px;
  }
  .KeratinLashLiftForNavbarInfoWaxing{
    padding-bottom: 40px;
  }
  .KeratinLashLiftForNavbarInfoAnnosune{
    padding-bottom: 40px;
  }
  .KeratinLashLiftForNavbarInfoLashLiftCertificationCourse{
    padding-bottom: 40px;
  }
  .KeratinLashLiftForNavbarInfoClassicLasCertificationCourse{
    padding-bottom: 40px;
  }
  .KeratinLashLiftForNavbarInfoVolumeLasCertificationCourse{
    padding-bottom: 40px;
  }
  .KeratinLashLiftForNavbarInfoRemoval{
    padding-bottom: 27px;
  }
  .KeratinLashLiftForNavbarInfoBrowsBykatelyn
{
  padding-bottom: 40px;

  }
  .KeratinLashLiftForNavbarInfoBrowsBykatelynEyebrowTouchUp
  {
    padding-bottom: 40px;
  margin-left: 50px;
    }

  .KeratinLashLiftForNavbarInfoMicroblading{
    padding-bottom: 40px;

  }
  .KeratinLashLiftForNavbarInfoMegavolume
{
  padding-bottom: 40px;

  }
  .KeratinLashLiftForNavbarInfoVolume
{
  padding-bottom: 40px;

  }
  .KeratinLashLiftForNavbarInfo{
    padding-bottom: 40px;

  }

  .KeratinLashLiftForNavbarInfoClassics{
    padding-bottom: 40px;

  }
  .HennaEyebrowsForNavbarInfo{
    margin-left: 90px;
  }
  .KeratinLashLiftForNavbarImg{
    height: 250px;
    width: 250px;
    float: left;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    margin-left: 60px;
    margin-bottom: 44px;

  }
  .KeratinLashLiftForNavbarImgArtists{
    height: 300px;
    width: auto;
    float: left;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    margin-left: 60px;
    margin-bottom: 44px;
  }
 .KeratinLashLiftForNavbarConatiner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background-color:  #67595E;
    
padding-top: 19px;
 }
 .KeratinLashLiftForNavbarConatinerMegaVolume
{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-color:  #67595E;
  position: relative;
  bottom: 16px;
padding-top: 19px;
 }
 .KeratinLashLiftForNavbarConatinerHybrids{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-color:  #67595E;
  position: relative;
  bottom: 6px;
padding-top: 19px;
 }

 .KeratinLashLiftForNavbarConatinerVolume{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-color:  #67595E;
  position: relative;
  bottom: 20px;
padding-top: 19px;
 }


 .KeratinLashLiftForNavbarConatinerLashLiftCourse{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-color:  #67595E;
  
padding-top: 41px;
 }
 .BrowsBykatelynForNavbarConatiner{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 406px;
  position: relative;
  /* top: 20px; */
  background-color:  #67595E;

 }
.KeratinLashLiftForNavbarHeading{
 font-size: 24px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;
    width: 100%;
    margin-left: 251px;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;

}
.KeratinLashLiftForNavbarHeadingLashLiftCourse{
  font-size: 24px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 100%;
  /* margin-left: 251px; */
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
 
 }
.KeratinLashLiftForNavbarHeadingVolume{
  font-size: 24px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 100%;
  text-align: center;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.KeratinLashLiftForNavbarHeadingArtists{
  font-size: 32px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 100%;
  /* margin-left: 251px; */
  text-align: center;
  color: white;
  padding-bottom: 20px;
}
.KeratinLashLiftForNavbarHeadingVolumeCourse{
  font-size: 24px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 100%;
  /* margin-left: 251px; */
  text-align: center;
  color: white;
  padding-bottom: 10px;
}
.KeratinLashLiftForNavbarHeadingCourses{
  font-size: 24px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;
    width: 100%;
    /* margin-left: 251px; */
    text-align: center;
    color: white;
    padding-bottom: 20px;
}
.forInleiLashLiftHeading{
    margin-left: 211px;

}
.forFullFacWaxingOfNavbarHeading{
  margin-left: 240px;
}
.forSorSideburnWaxingOfNavbarHeading{
  margin-left: 188px;
}
.BrowsBykatelynForNavbarHeading{
  font-size: 24px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 100%;
 text-align: center;
  color: white;
  /* margin-left: -49px; */
  padding-top: 10px;
  padding-bottom: 10px;

}
.BrowsBykatelynForNavbarHeadingEyebrowLamination{
  font-size: 24px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 100%;
 text-align: center;
  color: white;
  margin-left: 11px;
  padding-top: 10px;
  padding-bottom: 10px;

}
.BrowsBykatelynForNavbarHeadingHennaEyebrows{
  font-size: 24px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 100%;
 text-align: center;
  color: white;
  margin-left: 4px;
  padding-top: 10px;
  padding-bottom: 10px;

}
.UpperLipWaxingForNavbarHeading{
  font-size: 24px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 100%;
 text-align: center;
  color: white;
  /* margin-left: -49px; */
  padding-top: 10px;
  padding-bottom: 10px;
}
.RemovalForNavbarHeading{
  font-size: 24px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 100%;
  text-align: center;
  color: white;
  margin-left: -46px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.EyelashExtensionRemovalForNavbarHeading{
  font-size: 24px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 100%;
 text-align: center;
  color: white;
  margin-left: 4px;
 
}
.UpperLipWaxingForNavbarContent{
  font-size: 14px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 70%;
  margin-left: 65px;
  margin-top: 4px;
  text-align: center;
  color: white;
}
.BrowsBykatelynPriceForNavBar{
    font-size: 20px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;
    width: 100%;
    text-align: center;
    color: white;
    /* margin-left: -49px; */

}
.KeratinLashLiftForNavbarPrice{
  font-size: 20px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 100%;
  text-align: center;
  color: white;
  

}
.KeratinLashLiftForNavbarPriceMegavolume
{
  font-size: 20px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 100%;
  text-align: center;
  color: white;
  padding-right: 20px;
}
.KeratinLashLiftForNavbarPriceKeratinLashLift
{
  font-size: 20px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 100%;
  text-align: center;
  color: white;
  padding-right: 33px;
}
.KeratinLashLiftForNavbarPriceClassics{
  font-size: 20px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 100%;
  text-align: center;
  color: white;
  padding-right: 97px;
}
.RemovalForNavbarPrice{
  font-size: 20px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 100%;
  text-align: center;
  color: white;
  margin-left: -52px;
}
.KeratinLashLiftForNavbarContent{
    font-size: 14px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;
    width: 70%;
    margin-left: 100px;
    margin-top: 4px;
    text-align: center;
    color: white;
}
.KeratinLashLiftForNavbarContentFreckels{
  font-size: 14px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 70%;
  margin-left: 118px;
  margin-top: 4px;
  text-align: center;
  color: white;
}
.buyNowKeratinLashLiftForNavbarBtnArtists{
  height: 50px;
  width:235px;
  border-radius: 5px;
  font-family: 'Anton', sans-serif;
  font-family: 'Bebas Neue', cursive;
  font-size: 20px;
  padding-top: 4px;
  padding-right: 2px;
  box-shadow: black 5px 5px;
  cursor: pointer;
  border-color:white;
  background-color:white;
  margin-top: 12px;
  color: black;
}
.buyNowKeratinLashLiftForNavbarBtnBrowsByKatelyn{
  height: 50px;
  width:170px;
  border-radius: 5px;
  font-family: 'Anton', sans-serif;
  font-family: 'Bebas Neue', cursive;
  font-size: 20px;
  padding-top: 4px;
  padding-right: 2px;
  box-shadow: black 5px 5px;
  cursor: pointer;
  border-color:white;
  background-color:white;
  margin-top: 12px;
  color: black;
  margin-left: 282px;
}
.buyNowKeratinLashLiftForNavbarBtnBrowsByKatelynFreckels{
  height: 50px;
  width:170px;
  border-radius: 5px;
  font-family: 'Anton', sans-serif;
  font-family: 'Bebas Neue', cursive;
  font-size: 20px;
  padding-top: 4px;
  padding-right: 2px;
  box-shadow: black 5px 5px;
  cursor: pointer;
  border-color:white;
  background-color:white;
  margin-top: 12px;
  color: black;
  margin-left: 285px;
}
.buyNowKeratinLashLiftForNavbarBtnMicroblading{
  height: 50px;
  width:170px;
  border-radius: 5px;
  font-family: 'Anton', sans-serif;
  font-family: 'Bebas Neue', cursive;
  font-size: 20px;
  padding-top: 4px;
  padding-right: 2px;
  box-shadow: black 5px 5px;
  cursor: pointer;
  border-color:white;
  background-color:white;
  margin-top: 12px;
  color: black;
  margin-left: 272px;
}
.buyNowKeratinLashLiftForNavbarBtnMegaVolume{
  height: 50px;
  width:170px;
  border-radius: 5px;
  font-family: 'Anton', sans-serif;
  font-family: 'Bebas Neue', cursive;
  font-size: 20px;
  padding-top: 4px;
  padding-right: 2px;
  box-shadow: black 5px 5px;
  cursor: pointer;
  border-color:white;
  background-color:white;
  margin-top: 12px;
  color: black;
  margin-left: 275px;
}
.buyNowKeratinLashLiftForNavbarBtnClassics{
  height: 50px;
  width: 170px;
  border-radius: 5px;
  font-family: 'Anton', sans-serif;
  font-family: 'Bebas Neue', cursive;
  font-size: 20px;
  padding-top: 4px;
  padding-right: 2px;
  box-shadow: black 5px 5px;
  cursor: pointer;
  border-color: white;
  background-color: white;
  margin-top: 12px;
  color: black;
  margin-left: 238px;
}
.KeratinLashLiftForNavbarContentArtists{
  font-size: 14px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 70%;
  margin-left: 119px;
  margin-top: 4px;
  text-align: center;
  color: white;
}
.KeratinLashLiftForNavbarContentVolumeCourse{
  font-size: 14px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 70%;
  margin-left: 127px;
  margin-top: 4px;
  text-align: center;
  color: white;
}
.KeratinLashLiftForNavbarContentCourses{
  font-size: 14px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 70%;
  margin-left: 111px;
  margin-top: 4px;
  text-align: center;
  color: white;

}
.HennaEyebrowsForNavbarContent{
  font-size: 14px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 70%;
  margin-left: 70px;
  margin-top: 4px;
  text-align: center;
  color: white;
}
.eyebrowTouchUpForNavbarContent{
  font-size: 14px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 80%;
  margin-left: 5px;
  margin-top: 4px;
  text-align: center;
  color: white;
  padding-left: 104px;
}
.EyebrowLaminationForNavbarContent{
  font-size: 14px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 90%;
  margin-left: 90px;
  margin-top: 4px;
  text-align: center;
  color: white;
  position: relative;
  right: 55px;
}
.FullfaceWaxingForNavbarContent{
  font-size: 14px;
  /* font-family: '', serif; */
  font-family: 'Montserrat',sans-serif;
  width: 70%;
  margin-left: 115px;
  margin-top: 4px;
  text-align: center;
  color: white;
}
.buyNowEyebrowlaminationLiftForNavbarBtn{
  height: 50px;
  width:170px;
  border-radius: 5px;
  font-family: 'Anton', sans-serif;
  font-family: 'Bebas Neue', cursive;
  font-size: 20px;
  padding-top: 4px;
  padding-right: 2px;
  box-shadow: black 5px 5px;
  cursor: pointer;
  border-color:white;
  background-color:white;
  margin-top: 12px;
  color: black;
  margin-left: 114px;
}
.buyNowUpperLipWaxingForNavbarBtn{
  height: 50px;
  width:170px;
  border-radius: 5px;
  font-family: 'Anton', sans-serif;
  font-family: 'Bebas Neue', cursive;
  font-size: 20px;
  padding-top: 4px;
  padding-right: 2px;
  box-shadow: black 5px 5px;
  cursor: pointer;
  border-color:white;
  background-color:white;
  margin-top: 12px;
  color: black;
  margin-left: 137px;
}
.buyNowEyebrowTouchUpForNavbarBtn{
  height: 50px;
  width:170px;
  border-radius: 5px;
  font-family: 'Anton', sans-serif;
  font-family: 'Bebas Neue', cursive;
  font-size: 20px;
  padding-top: 4px;
  padding-right: 2px;
  box-shadow: black 5px 5px;
  cursor: pointer;
  border-color:white;
  background-color:white;
  margin-top: 12px;
  color: black;
  margin-left: 185px;
}
.buyNowHennaEyebrowsForNavbarBtn{
  height: 50px;
  width:170px;
  border-radius: 5px;
  font-family: 'Anton', sans-serif;
  font-family: 'Bebas Neue', cursive;
  font-size: 20px;
  padding-top: 4px;
  padding-right: 2px;
  box-shadow: black 5px 5px;
  cursor: pointer;
  border-color:white;
  background-color:white;
  margin-top: 12px;
  color: black;
  margin-left: 143px;
}
.buyNowKeratinLashLiftForNavbarBtn{
    height: 50px;
    width:170px;
    border-radius: 5px;
    font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    padding-top: 4px;
    padding-right: 2px;
    box-shadow: black 5px 5px;
    cursor: pointer;
    border-color:white;
    background-color:white;
    margin-top: 12px;
    color: black;
    margin-left: 316px;
    
}
.buyNowKeratinLashLiftForNavbarBtnKeratinlashLift1{
  height: 50px;
    width:170px;
    border-radius: 5px;
    font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    padding-top: 4px;
    padding-right: 2px;
    box-shadow: black 5px 5px;
    cursor: pointer;
    border-color:white;
    background-color:white;
    margin-top: 12px;
    color: black;
    margin-left: 273px;
}
.buyNowKeratinLashLiftForNavbarBtnKertainLashLift2{
  height: 50px;
  width:170px;
  border-radius: 5px;
  font-family: 'Anton', sans-serif;
  font-family: 'Bebas Neue', cursive;
  font-size: 20px;
  padding-top: 4px;
  padding-right: 2px;
  box-shadow: black 5px 5px;
  cursor: pointer;
  border-color:white;
  background-color:white;
  margin-top: 12px;
  color: black;
  margin-left: 273px;
}
.buyNowRemovalForNavbarBtn{
  height: 50px;
  width:170px;
  border-radius: 5px;
  font-family: 'Anton', sans-serif;
  font-family: 'Bebas Neue', cursive;
  font-size: 20px;
  padding-top: 4px;
  padding-right: 2px;
  box-shadow: black 5px 5px;
  cursor: pointer;
  border-color:white;
  background-color:white;
  margin-top: 12px;
  color: black;
  margin-left: 233px;
}
.btnforInlashLift{
    margin-left: 313px;
}
.btnforInlashLiftVolume{
  margin-left: 273px;
}
.btnforInlashLiftHybrids{
  margin-left: 282px;
}
.btnforSideburnWaxingInNavbar{
  margin-left: 235px;
}
.btnforFullFaceWaxingOfNavbar{
  margin-left: 282px;
}
.btnforBrowsWaxingforNavbar{
  margin-left: 231px;

}
.ContainerForbuyNowKeratinLashLiftForNavbarBtn{
    text-align: center;
    /* margin-top: 20px; */
    display: flex;
    justify-content: flex-start;
}
.ContainerForbuyNowKeratinLashLiftForNavbarBtnArtists{
  text-align: center;
  /* margin-top: 20px; */
  display: flex;
  justify-content: center;
  margin-left: 33px;
}
.ContainerForbuyNowKeratinLashLiftForNavbarBtnArtistsJackie{
  text-align: center;
  /* margin-top: 20px; */
  display: flex;
  justify-content: center;
  margin-left: 26px;
}
.ContainerForbuyNowKeratinLashLiftForNavbarBtnCourses{
  text-align: center;
  /* margin-top: 20px; */
  display: flex;
  justify-content: center;
}