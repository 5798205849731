.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    width: 500px;
    height: auto;
  }

  .classicsByAnnosuneswiper{
    height: 500px;
    margin-top: 20px;
    /* margin-bottom: 100px; */
  }
  .ClassicsByannosuneContainer{
    height:444px;
  }
  .ClasiicLashFullLiftAnnosuneImg{
    height: 300px;
    width: 300px;
    float: left;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    margin-left: 100px;
  }
 .ClasiicLashFullLiftAnnosuneConatiner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    
margin-top: 70px;
 }
.ClasiicLashFullLiftAnnosuneHeading{
 font-size: 24px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;
    width: 100%;
    /* margin-left: 50px; */
    text-align: center;

}
.ClasiicLashFullLiftAnnosunePrice{
    font-size: 20px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;
    width: 100%;
    /* margin-left: 50px; */
text-align: center;
}
.ClasiicLashFullLiftAnnosuneContent{
    font-size: 14px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;
    width: 90%;
    margin-left: 50px;
    margin-top: 20px;
    text-align: center;
}
.buyNowClassicLashFullLiftByAnnosuneBtn{
    height: 50px;
    width:340px;
    border-radius: 5px;
    font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    padding-top: 4px;
    padding-right: 2px;
    box-shadow: black 5px 5px;
    cursor: pointer;
    border-color: #EFD3D2;
    background-color: #EFD3D2;
    margin-top: 20px;
    color: black;
}
.ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn{
    text-align: center;
    margin-top: 20px;
}

@media (max-width:1023px) {
  .ClasiicLashFullLiftAnnosuneConatiner{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .ClasiicLashFullLiftAnnosuneImg{

    margin-left: 0px;
    margin-bottom: 20px;
  }
  .ClasiicLashFullLiftAnnosuneContent{
    /* margin-left: 66px; */
    margin-top: 0px;
    width: 70%;
  }
  .ClasiicLashFullLiftAnnosuneHeading{
    margin-bottom: 20px;
  }
  .ClasiicLashFullLiftAnnosuneHeading{
    font-size: 20px;
  }
  .ClassicsByannosuneContainer{
    height:910px;
  }
}