*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/* .eyelashServiceImgContainer{
    position: relative;

} */

.eyelashservicesImg{
    width: 50%;;
    position: relative;
    float:left;
}
.eyeBrowHome2{
    width: 50%;
}

.eyeLashAndBrowHome3Container{
    position: relative;
    height: 740px;
}

.eyelashLinkss{
    position: absolute;
    /* top: 
    -180px;
    left: 10px; */

}

.eyelashLinkss a:not(:last-child){
    display: none;
}

.eyelashLinkss:hover a:not(:first-child){
    display: block;
    transition: 0.2s;
    
}

.eyebrowLinkss a:not(:last-child){
    display: none;
}

.eyebrowLinkss:hover a:not(:last-child){
    display: block;
    transition: 0.2s;
}
.eyebrowLinkss{
    position: absolute;
    bottom: 10px;
    right: 460px;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    border-radius: 20px;
}

.eyelashLinkss{
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    /* height:1000px; */
    position: absolute;
    bottom:10px;
    left:10px; 
    border-radius: 20px;
    
}

/* , .eyelashLinkss1, .eyelashLinkss2 ,.eyelashLinkss3,.eyelashLinkss4 ,.eyelashLinkss5, .eyelashLinkss6,.eyelashLinkss7 */
/* .eyelashLinkss:hover  + .eyelashLinkss1 .eyelashLinkss2 .eyelashLinkss3 .eyelashLinkss4 .eyelashLinkss5 .eyelashLinkss6 .eyelashLinkss7 {

display: block;

} */



.eyelashLinksHeadingg{
    /* position: absolute; */
    /* top: 
    -700px; */
    left: 10px;
    font-size: 28px;
    color: whitesmoke;
    width: 410px;
    /* background-color: rgba(0, 0, 0, 0.3); */
    text-shadow: 4px 4px 8px #ef7c8e85;;
    height: 60px;
    
    padding-left: 10px;
    padding-top: 10px;
    font-family: 'Montserrat',sans-serif;


}

.eyelashLinkss1{
    /* position: absolute; */
    /* top: 
    -630px; */
    left: 10px;
    font-size: 28px;
    color: whitesmoke;
    width: 410px;
    /* background-color: rgba(0, 0, 0, 0.3); */
    text-shadow: 4px 4px 8px #ef7c8e85;;
    height: 40px;
    /* box-shadow: 0 15px 35px rgb(0, 0, 0); */
    border-radius: 20px;
    /* backdrop-filter: blur(10px); */
    /* border: 1px solid rgba(255, 255, 255, 0.25); */
    /* border-top: 1px solid rgba(255, 255, 255, 0.5); */
    /* border-left: 1px solid rgba(255, 255, 255, 0.5);   
     /* border-left: 1px solid rgba(255, 255, 255, 0.5); */
    /* border-right: 1px solid rgba(255, 255, 255, 0.5);  */
    overflow: hidden;
    /* border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px; */
    padding-left: 10px;
    padding-top: 10px;
    font-family: 'Montserrat',sans-serif;
    font-size: 16px;
}
.eyelashLinkss2{
    /* position: absolute; */
    /* top: 
    -560px; */
    left: 10px;
    font-size: 28px;
    color: whitesmoke;
    width: 410px;
    /* background-color: rgba(0, 0, 0, 0.3); */
    text-shadow: 4px 4px 8px #ef7c8e85;;
    height: 40px;
    /* box-shadow: 0 15px 35px rgb(0, 0, 0); */
    /* border-radius: 20px; */
    /* backdrop-filter: blur(10px); */
    
    /* border: 1px solid rgba(255, 255, 255, 0.25); */
    /* border-top: 1px solid rgba(255, 255, 255, 0.5); */
    /* border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px; */
    padding-left: 10px;
    padding-top: 10px;
    font-family: 'Montserrat',sans-serif;
    font-size: 16px;


}

.eyelashLinkss3{
    /* position: absolute; */
    /* top: 
    -490px; */
    left: 10px;
    font-size: 28px;
    color: whitesmoke;
    width: 410px; 
    /* background-color: rgba(0, 0, 0, 0.3); */
    text-shadow: 4px 4px 8px #ef7c8e85;;
    height: 40px;
    /* box-shadow: 0 15px 35px rgb(0, 0, 0); */
    border-radius: 20px;
    /* backdrop-filter: blur(10px); */
    /* border: 1px solid rgba(255, 255, 255, 0.25); */
    /* border-top: 1px solid rgba(255, 255, 255, 0.5); */
    /* border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);

    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px; */
    padding-left: 10px;
    padding-top: 10px;
    font-family: 'Montserrat',sans-serif;
    font-size: 16px;    


}

.eyelashLinkss4{
    /* position: absolute; */
    /* top: 
    -420px; */
    left: 10px;
    font-size: 28px;
    color: whitesmoke;
    width: 410px; 
    /* background-color: rgba(0, 0, 0, 0.3); */
    text-shadow: 4px 4px 8px #ef7c8e85;;
    height: 40px;
    /* box-shadow: 0 15px 35px rgb(0, 0, 0); */
    /* border-radius: 20px; */
    /* backdrop-filter: blur(10px); */
    /* border: 1px solid rgba(255, 255, 255, 0.25); */
    /* border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5); */
    /* border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);

    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px; */
    /* border-top-left-radius: 0px;
    border-top-right-radius: 0px; */
    padding-left: 10px;
    padding-top: 10px;
    font-family: 'Montserrat',sans-serif;
    font-size: 16px;
}

.eyelashLinkss5{
    /* position: absolute; */
    /* top: 
    -350px; */
    left: 10px;
    font-size: 28px;
    color: whitesmoke;
    width: 410px; 
    /* background-color: rgba(0, 0, 0, 0.3); */
    text-shadow: 4px 4px 8px #ef7c8e85;;
    height: 40px;
    /* box-shadow: 0 15px 35px rgb(0, 0, 0); */
    /* border-radius: 20px; */
    /* backdrop-filter: blur(10px); */
    /* border: 1px solid rgba(255, 255, 255, 0.25); */
    /* border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5); */
    /* border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5); */

    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding-left: 10px;
    padding-top: 10px;
    font-family: 'Montserrat',sans-serif;
    font-size: 16px;
}

.eyelashLinkss6{
    /* position: absolute; */
    /* top: 
    -280px; */
    left: 10px;
    font-size: 28px;
    color: whitesmoke;
    width: 410px; 
    /* background-color: rgba(0, 0, 0, 0.3); */
    text-shadow: 4px 4px 8px #ef7c8e85;;
    height: 40px;
    /* box-shadow: 0 15px 35px rgb(0, 0, 0); */
    /* border-radius: 20px; */
    /* backdrop-filter: blur(10px); */
    /* border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5); */
    /* border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5); */

    /* border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px; */
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding-left: 10px;
    padding-top: 10px;

    font-family: 'Montserrat',sans-serif;
    font-size: 16px;
}

.eyelashLinkss7{
    /* position: absolute; */
    /* top: 
    -210px; */
    left: 10px;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 28px;
    color: whitesmoke;
    width: 410px;
    text-shadow: 4px 4px 8px #ef7c8e85;;
    /* background-color: rgba(0, 0, 0, 0.3); */
    height: 40px;
    /* box-shadow: 0 15px 35px rgb(0, 0, 0); */
    /* border-radius: 20px; */
    /* backdrop-filter: blur(10px); */
    /* border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5); */
    /* border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5); */

    /* border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px; */
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    font-family: 'Montserrat',sans-serif;
    font-size: 16px; 
}

.eyebrowLinksHeadingg{
    /* position: absolute;
    /* top: 
    -700px; */
    /*right: 10px; */
    font-size: 40px;
    color: whitesmoke;
    width: 280px;
    /* background-color: rgba(0, 0, 0, 0.3); */
    text-shadow: 4px 4px 8px  #ef7c8e85;;
    height: 60px;
    /* box-shadow: 0 15px 35px rgb(0, 0, 0); */
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px; */
    /* backdrop-filter: blur(10px); */
    /* border: 1px solid rgba(255, 255, 255, 0.25); */
    /* border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5); */
    /* border-right: 1px solid rgba(255, 255, 255, 0.5); */
    padding-left: 10px;
    padding-top: 10px;
    font-family: 'Montserrat',sans-serif;
    font-size: 28px;

}

.eyebrowLinkss1,.eyebrowLinkss2,.eyebrowLinkss3,.eyebrowLinkss4,.eyebrowLinkss5,.eyebrowLinkss6,.eyebrowLinkss7{
    padding-left: 10px;
    padding-top: 10px;
   
    color: whitesmoke;
    width: 280px;
    text-shadow: 4px 4px 8px  #ef7c8e85;;
    /* background-color: rgba(0, 0, 0, 0.3); */
    height: 40px;
    font-family: 'Montserrat',sans-serif;
    font-size: 16px;
}

   @media (max-width:1023px){
.eyelashLinksHeadingg, .eyebrowLinksHeadingg{
font-size: 14px;
width:250px;
height: 50px;
}
.eyelashLinkss1 ,.eyelashLinkss2 , .eyelashLinkss3, .eyelashLinkss4, .eyelashLinkss5, .eyelashLinkss6, .eyelashLinkss7{

    font-size: 14px;
    width:150px;
    height: 30px;
}
.eyelashLinkss{
    bottom: 400px;
    width: 250px;
}
.eyeLashAndBrowHome3Container{
height: auto;
display: flex;
flex-direction: column;
}
.eyelashservicesImg, .eyeBrowHome2{
    width: 100%;
}
.eyebrowLinkss{
    right: 115px;
}

.eyebrowLinkss1, .eyebrowLinkss2{
    font-size: 14px;
    width:150px;
    height: 30px;
}
}