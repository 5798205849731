.btnBookNowForMicroBladingService{
    height: 70px;
    width:170px;
    border-radius: 13px;
    font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 31px;
    padding-top: 4px;
    box-shadow: lightskyblue 5px 5px;
    cursor: pointer;
    border-color: #EFD3D2;;
    background-color: #EFD3D2;
    margin-top: 20px;

}

.btnBookNowForMicroBladingService:hover{
    padding-top: 4px;
    transition: all 0.3s ease-out;
    background-color:whitesmoke;
    border-radius: 13px;
    border: 1px  #EFD3D2;
    color: black;
    
}