*{
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
    
}
.NavbarConatinerOfWaxingPage{
position: sticky;
top: 0;
z-index: 100;

}
.MobileNavbarWaxing{
    display: none;
}
.WaxingPageMainContainer{
    /* background-image:linear-gradient(to left bottom, #ca92b5, #be9cce, #a7a8e4, #86b6f1, #5ec3f4, #51c2f2, #41c0f0, #2cbfee, #47b0ee, #669fe7, #828dd9, #987ac4); ; */
    /* height: 640px; */
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* margin-top: 200px; */
}


.WaxingPageServiceSection{
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 900px; */
    padding-top: 25px;
    margin-bottom: 28px;
    margin-top: 36px;
}

.SideburnsWaxingPageServiceSection{
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 900px; */
    padding-top: 25px;
    margin-bottom: 10px;
}
.WaxingPageServiceimage{
    height: 300px;
    width: 300px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}
.SideburnsWaxingPageServiceimage{
    height: 300px;
    width: 300px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    position: relative;
    left: 18px;

    bottom:16px
}
.fullfaceWaxingImg{
    position: relative;
    left: 45px;
    bottom: 16px;
}
.BrowsWaxingImg{
    position: relative;
    left: 7px;
    bottom: 16px;
}
.upperLipWaxingImg{
    position: relative;
    /* left: 14px; */
    bottom: 16px;
}
.HeadingOfWaxingPage{
font-size: 28px;
/* font-family: '', serif; */
font-family: 'Montserrat',sans-serif;
color:white;
background-color: black;
width: 100%;
text-align: center;
height: 80px;
padding-top: 20px;

}
.WaxingPageServiceHeading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;
margin-left: 50px;
    margin-bottom: 10px;
}
.SideburnsWaxingPageServiceHeading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;

    margin-bottom: 10px;
}
.KeratinEyelashLiftPrice{
font-size: 20px;
}
.SideburnsWaxingPageLiftPrice{
    font-size: 20px;
margin-left: 90px;
}
.SideburnsWaxingPageServiceSectionForMarginBottom{
    margin-bottom: 20px;
}
.WaxingPagePrice{
font-size: 20px;
margin-left: 30px; 
}
.WaxingPageServiceSectionHeadingInfo{
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 10px;
    /* font-weight: 900; */
    color: black;
    /* text-shadow: 4px 4px 8px  #ef7c8e66;; */
    padding-left: 60px;
}
.SideburnsWaxingPageServiceSectionHeadingInfo{
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 10px;
    /* font-weight: 900; */
    color: black;
    /* text-shadow: 4px 4px 8px  #ef7c8e66;; */
    padding-left: 120px;
}
.SideburnsWaxingPageServiceSectionInfo{
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
font-weight: 100;
    width: 80%;
    /* padding-left: 100px;  */
    position: relative;
    left: 65px;
    color: black;
    text-align: center;
}
.bookNowButtonForWaxingPageServicePage{
margin-left: 40px;
}
.bookNowButtonForSideburnsWaxingPageServicePage{
margin-left: 100px;

}
.WaxingPageServiceSectionInfo{
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
font-weight: 100;
    width: 100%;
    padding-left: 65px; 
    color: black;

}
.InleiLashListServiceSectionForGivingMargin{
    margin-bottom: 22px;
}

@media (max-width:1023px) {
    .WaxingPageServiceSection{
        flex-direction: column;
    }
    .WaxingPagePrice{
        margin-left: 0px;
    }
    .WaxingPageServiceHeading{
        margin-left: 0px;
    }
    .WaxingPageServiceSectionInfo{
        padding-left: 0px;
    }
    .WaxingPageServiceSectionHeadingInfo{
        padding-left: 0px;
    }
    .bookNowButtonForWaxingPageServicePage{
        margin-left: 0px;
    }
    .classicLashCertificationCourseSection{
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .classicLashCertificationCourseimage{
        width: 300px;
        height: auto;
        margin-top: 10px;
    }
    .classicLashCertificationCourseSectionHeadingInfo{
        text-align: center;
        font-size: 28px;
        padding-left: 0px;
        width: 80%;
        margin-top: 10px;
    }
    .classicLashCertificationCourseSectionInfo{
font-size: 14px;
width: 84%;
margin-left: 0px;
text-align: center;
    }

    .MobileNavbarWaxing{
        display: block;
        width: 100%;
        position: static;
top: 0;
z-index: 100;
    }
    .NavbarConatinerOfWaxingPage{
        display: none;
    }
    .SideburnsWaxingPageServiceSection{
        flex-direction: column;
    }
    .SideburnsWaxingPageServiceSectionHeadingInfo{
        padding-left: 0px;
    }
    .SideburnsWaxingPageLiftPrice{
        margin-left: 0px;
    }
    .SideburnsWaxingPageServiceSectionInfo{
        left: 0;
    }
    .SideburnsWaxingPageServiceimage{
        left: 0px;
    }
    .bookNowButtonForSideburnsWaxingPageServicePage{
        margin-left: 0px;
    }
}