* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.containerFooter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* background-color: wlacksmoke; */
  height: 350px;
  background-color: #EFD3D2;
}

.embellashesInfo {
  width: 18%;
  margin-left: 100px;
}

.embellashesInfoLines {
  font-family: "Montserrat", sans-serif;

  padding-top: 20px;
  font-size: 16px;
  font-weight: 100;
  color: black;
}

.socialMediaLinks {
  padding-top: 20px;
  cursor: pointer;
}
.socialMediaLinks ul {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.instagramIcon {
  margin-left: 20px;
  font-size: 20px;
}
.facebookicon {
  font-size: 20px;
}

.quickLinks {
  width: 20%;
  margin-left: 100px;
}

.quickLinksheading {
  font-size: 22px;
  transition: 0.3s all linear;
  /* font-size: 16px; */
  /* font-family: 'Oswald', sans-serif; */
  /* font-family: 'Anton', sans-serif;
        font-family: 'Bebas Neue', cursive; */
  /* font-family: Escrow Condensed,Georgia,serif; */
  font-family: "Montserrat", sans-serif;

  color: black;
  /* text-shadow: 4px 4px 8px whitesmoke;; */
  /* text-shadow: 1px 1px 4px #2d6ee0;; */
}
/* .quickLinksheading:hover{
        padding-top: 4px;
        padding-left: 15px;
        transition: all 0.3s ease-out;
        background-color: rlack236, 133, 245, 0.884);
        border-radius: 13px;
        border: 1px rgba(236, 133, 245, 0.884);
        color: wlacksmoke;
        width: 110px;
        box-shadow: lightskyblue 5px 5px;;
        transition: 0.3s  all linear;
        
        
    } */

.quickLinks ul {
  list-style: none;
}
.quickLinksLines {
  font-size: 16px;
  margin-top: 5px;
  width: 200px;
  transition: 0.3s all ease;
  font-size: 18px;
  /* font-family: 'Oswald', sans-serif; */
  /* font-family: Escrow Condensed,Georgia,serif; */
  font-family: "Montserrat", sans-serif;

  font-weight: 100;
  color: black;
  /* text-shadow: 4px 4px 8px #FF0000;;
            */
  /* text-shadow: 2px 2px 4px #2d6ee0;; */
}

/* .quickLinksLines:hover{
        padding-top: 4px;
        padding-left: 15px;
        transition: all 0.3s ease-out;
        background-color: rlack236, 133, 245, 0.884);
        border-radius: 13px;
        border: 1px rgba(236, 133, 245, 0.884);
        color: wlacksmoke;
        width: 110px;
        box-shadow: lightskyblue 5px 5px;;
        cursor: pointer;
     
    } */
.footerLinks {
  color: black;
  text-decoration: none;
  transition: 0.2s all ease;
  font-size: 16px;
  /* font-family: 'Oswald', sans-serif; */
  /* font-family: Escrow Condensed,Georgia,serif; */
  font-family: "Montserrat", sans-serif;

  /* text-shadow: 2px 2px 4px #2d6ee0;; */

  /* text-shadow: 4px 4px 8px whitesmoke;; */
}

.subscribeLine {
  /* font-size: 0px; */
  font-size: 18px;
  /* font-family: 'Oswald', sans-serif; */
  /* font-family: 'Anton', sans-serif;
        font-family: 'Bebas Neue', cursive; */
  /* font-family: Escrow Condensed,Georgia,serif; */
  font-family: "Montserrat", sans-serif;

  color: black;
  /* text-shadow: 4px 4px 8px whitesmoke;; */
  /* text-shadow: 2px 2px 4px #2d6ee0;; */
}
.email {
  font-size: 18px;
  text-decoration: none;
  color: black;
  /* text-shadow: 2px 2px 4px #2d6ee0;; */
  font-family: "Montserrat", sans-serif;

  /* font-family: Escrow Condensed,Georgia,serif; */
}
/* .quickLinksLine:hover{
        padding-top: 4px;
        padding-left: 15px;
        transition: all 0.3s ease-out;
        background-color: rlack236, 133, 245, 0.884);
        border-radius: 13px;
        border: 1px rgba(236, 133, 245, 0.884);
        color: wlacksmoke;
        width: 110px;
        box-shadow: lightskyblue 5px 5px;;
        cursor: pointer;
    
    } */

/* .subscribeWithUs:hover{
        padding-top: 4px;
        padding-left: 15px;
        transition: all 0.3s ease-out;
        background-color: rlack236, 133, 245, 0.884);
        border-radius: 13px;
        border: 1px rgba(236, 133, 245, 0.884);
        color: wlacksmoke;
        width: 110px;
        box-shadow: lightskyblue 5px 5px;;
    } */


    @media (max-width:1023px){
    .containerFooter{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        /* background-color: wlacksmoke; */
        height: auto;
        width: 100%;
        background-color: #EFD3D2;
    }

    .footerLogoImgg{
        margin-top: 20px;
    }
    .embellashesInfo , .quickLinks , .subscribeWithUs{
        margin-left: 18px;
        width: 90%;
        text-align: center;
}
    
    .embellashesInfoLines{
        width: 100%;
        height: auto;
    }
    .socialMediaLinks {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .quickLinksLines{
        width:100%;
    }
    .quickLinksheading, .subscribeLine{
        margin-top: 20px;
        text-decoration: underline;

    }
    .label{
        display: flex;
        flex-direction: column;
        
    }
    .email{
        text-decoration: none;
    }

    .emaiForFooter{
        padding-top: 10px;
    }
    .inputForEmailInFooter{
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        border-radius: 5px;
        /* border: 1px solid transparent;
        border-bottom-color: #E8B4B8; */
      /* height: 40px;
      width: 340px; */
      margin-top: 10px;
      color: black;
    }
    }