*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.mainFlexEyeBrowHomeContainer{
    display: flex;
 flex-direction: column;
    height: 850px;
    width: 100%;
    position: relative;
    /* background-image:linear-gradient(to left bottom, #ca92b5, #be9cce, #a7a8e4, #86b6f1, #5ec3f4, #51c2f2, #41c0f0, #2cbfee, #47b0ee, #669fe7, #828dd9, #987ac4); ; */
    /* background-image: linear-gradient(to right top, #ef9273, #f5a58c, #fab8a5, #fdcbbe, #ffdfd7, #e8d1ce, #d1c4c3, #bab7b7, #8a8889, #5d5c5d, #343334, #0d0d0d); */
    /* background-image: linear-gradient(to right top, #99ebf3, #7bcab7, #74a779, #758143, #74591c, #804714, #8a301b, #8f072b, #aa004c, #bd0078, #c101ad, #ad3ee9); */
}

.mainflexEyeBrowHomeContainerHeading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 320px;
  
}


.mainFlexEyebrowContainerContent1{
    font-family: 'Montserrat', sans-serif;

    top: 100px;
    left: 40px;
    font-size: 40px;
    color: black;
    font-weight: 100;
    text-transform: uppercase;
    position: absolute;
}

.mainFlexEyebrowContainerContent2{
    font-family: 'Montserrat', sans-serif;

    top: 160px;
    left: 40px;
    font-size: 100px;
    color: black;
    font-weight: 1000;
    text-transform: uppercase;
    position: absolute;
    width: 200px;
}
.mainflexEyeBrowHomeContainerHeading1{
    /* width: 700px; */
    width: 100%;
    height: 100px;
    font-size: 80px;
    /* font-family: 'Bebas Neue', cursive; */
    font-family: Retina,Arial,Helvetica,sans-serif;
padding-left: 40px;
    font-weight: 400;
    color: whitesmoke;
    /* text-shadow: 4px 4px 8px #28e417;; */
    text-shadow: 4px 4px 8px #2d6ee0;;

    text-transform: uppercase;

}

.mainflexEyeBrowHomeContainerHeading2ndPart{
    /* width: 700px; */
    width: 100%;
    height: 100px;
    padding-left: 400px;
    font-size: 80px;
    /* font-family: 'Bebas Neue', cursive; */
    font-family: Retina,Arial,Helvetica,sans-serif;
    text-transform: uppercase;

    font-weight: 400;
    color: whitesmoke;
    text-shadow: 4px 4px 8px #2d6ee0;;
}
.mainflexEyeBrowHomeContainerHeading2{
    /* padding-top: 100px; */
    font-size: 80px;
    font-family: 'Bebas Neue', cursive;
    padding-right: 50px;
    color: whitesmoke;
    font-weight: 900;
    /* text-shadow: 4px 4px 8px #dae41c;; */
    text-shadow: 4px 4px 8px #2d6ee0;;

}

.mainflexEyeBrowHomeContainerHeading3{
    font-size: 40px;
    /* width: 700px; */
    width: 100%;
    /* font-family: 'Bebas Neue', cursive; */
    font-family: Escrow Condensed,Georgia,serif;
/* text-transform: uppercase; */
    padding-left: 80px;
    padding-top: 30px;
    color: whitesmoke;
    /* font-weight: 900; */
    /* text-shadow: 2px 2px 8px #0fa3e2;; */
    text-shadow: 4px 4px 8px #2d6ee0;;


}

.mainflexEyeBrowHomeContainerHeading4{
    font-size: 40px;
    /* width: 700px; */
    width: 100%;
    /* font-family: 'Bebas Neue', cursive; */
    font-family: Escrow Condensed,Georgia,serif;
padding-top: 10px;
    padding-left: 380px;
    color: whitesmoke;
    /* font-weight: 100; */
    /* text-shadow: 2px 2px 8px #FF0000;; */

/* text-transform: uppercase; */
}

.mainflexEyeBrowHomeContainerHeading5{
    font-size: 28px;
    /* width: 700px; */
    width :100%;
    /* font-family: 'Bebas Neue', cursive; */
    padding-left: 450px;
    color: whitesmoke;
    /* text-shadow: 2px 2px 8px #FF0000;; */
    text-shadow: 4px 4px 8px #2d6ee0;;

    font-family: Escrow Condensed,Georgia,serif;
    /* text-transform: uppercase; */
    padding-top: 10px;

}

.rightImgEyeBrowHomeFlexContainer{
    height: 100%;
    width: 100%;
   

}

.buttonCSS{
    position: absolute;
    /* left: -400px; */
    top: 746px;
    left: 50px;
}

@media (max-width:1023px){
    .mainFlexEyeBrowHomeContainer{
        width: 100%;
        height: 224px;
    }
    .rightImgEyeBrowHomeFlexContainer{
        width: 100%;
        height: auto;
}
.mainFlexEyebrowContainerContent1, .mainFlexEyebrowContainerContent2{
    font-size: 28px;
    
}
.mainFlexEyebrowContainerContent1{
    left: 37px;
    position: relative;
    top: -201px;
    font-size: 20px;
    width: 100px;
}
.mainFlexEyebrowContainerContent2{
    position: relative;
    top: -184px;
    font-size: 20px;
    left: 37px;
    width: 100px;
}
.buttonCSS{
    position: absolute;
    /* left: -400px; */
    top: 154px;
    left: 211px;
}
}

