*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.allThingsContainerServiceSlider{
    background-color: white;
    /* margin-top: 60px; */
    height: 700px;
    /* margin-top: 10px; */
    padding-bottom: 60px;
} 


.headingOurServices{
   
    /* font-family: Retina,Arial,Helvetica,sans-serif; */
    font-family: 'Montserrat',sans-serif;

    font-weight: 700 ;
   position: relative;
   /* right: 350px; */
   width: 100%;
   color: black; 
   font-size: 32px;
   top: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   /* font-family: 'Bebas Neue', cursive; */
text-transform: uppercase;
/* text-shadow: 4px 4px 8px #4baeec85; */
    position: relative;
    top: 70px;
    padding-bottom: 10px;
}

.swiper {
    width: 100%;
    height: 100%;
    /* color: white ; */
  }
  
  .swiper-slide {
      width: 500px;
      height: auto;
    
  background-color: white;
    
  }
  
  .swiper-slide img {
    /* display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; */
  
  }

  .swiper-wrapper{
    height: auto !important;
   
  }

  .allThingsContainerServiceSlider .swiper-wrapper{
    padding-top: 60px;
  }

     @media (max-width:1023px){
.headingOurServices{
text-align: center;
} 
  }