* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .bodyOfEyelashExtensionServicePage {
 
    background-color:  #EFD3D2;
  }
 
  .microbladPlusShadeThroughNavbarPrice{
    position: relative;
    bottom: 20px;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
  }
  .toSetBookNowButtonInBrowsByKatelynPageInHennaEyebrows{
    position: initial;
  }
  .tosetButtonOfEyebrowLamination{
    position: initial;

  }
  .EyelashExtensionServicePageFold1Container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    background-color: white;
  }
  
  .KeratinlashLiftServiceInfoBelowHeadingList1 {
    list-style-type: circle;
    margin-top: 15px;
    padding-left: 40px;
    padding-bottom: 15px;
    text-decoration: underline;
    line-height: 30px;
    /* color: black; */
  }
  .EyelashExtensionServicePageImg1 {
    height: 500px;
    width: 450px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 10px;
    margin-left: 0px;
  }
  
  .KeratinlashLiftServiceInfo {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 240px;
/* position: relative; */
/* left: 210px; */
    margin-bottom: 10px;
  }
  .waxingServiceHeadingForeyelashextensionPage{
    width: 100%;
    height: 100px;
    font-size: 28px;
    /* font-family: 'Bebas Neue', cursive; */
    font-family: "Montserrat", sans-serif;
    /* background-color: rgb(232, 106, 167); */
    text-shadow: 4px 4px 8px #f5f5f542;
    color: black;
    /* padding-left: 70px; */
    font-weight: 500;
    position: relative;
    top: -20px;
  }
  .KeratinlashLiftServiceHeading {
    width: 100%;
    height: 100px;
    font-size: 28px;
    /* font-family: 'Bebas Neue', cursive; */
    font-family: "Montserrat", sans-serif;
    /* background-color: rgb(232, 106, 167); */
    text-shadow: 4px 4px 8px #f5f5f542;
    color: black;
    /* padding-left: 70px; */
    font-weight: 500;
    position: relative;
    top: -20px;
  }
  
  .KeratinlashLiftServiceHeading2 {
    width: 800px;
    height: 100px;
    font-size: 80px;
    /* font-family: 'Bebas Neue', cursive; */
    /* font-family: Retina,Arial,Helvetica,sans-serif; */
    font-family: "Montserrat", sans-serif;
  
    font-weight: 100;
    color: whitesmoke;
    /* text-shadow: 4px 4px 8px #FF0000;; */
    text-shadow: 4px 4px 8px #2d6ee0;
  }
  .KeratinlashLiftServiceInfoBelowHeading-eyelashExtensionServicePage {
    /* width: 750px; */
    font-size: 15px;
    /* font-family: 'Bebas Neue', cursive; */
    /* font-family: 'Oswald', sans-serif; */
    /* margin: 10px; */
    /* font-family: Escrow Condensed,Georgia,serif; */
    font-family: "Montserrat", sans-serif;
  
    font-weight: 120;
    /* text-shadow: 4px 4px 8px #2d6ee0;; */
    width: 500px;
    text-align: left;
    padding-right: 00px;
    /* background-color: rgb(232, 106, 167); */
    text-shadow: 4px 4px 8px #f5f5f542;
    color: black;
    /* text-shadow: 4px 4px 8px #FF0000;; */
  
    position: relative;
    top: -70px;
    /* margin-top: 90px; */
  }
  
  .bookNowButtonKeratinlashLiftService {
    /* margin-right: 200px; */
  
    position: relative;
    top: -10px;
    left: 120px;
 

  }

  .bookNowButtonOnTheMicroBladingPage{
    height: 50px;
    width:170px;
    border-radius: 5px;
    font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    padding-top: 4px;
    box-shadow: black 5px 5px;
    cursor: pointer;
    border-color: #EFD3D2;
    background-color: #EFD3D2;
    margin-top: 20px;
    color: black;

}

.bookNowButtonOnTheMicroBladingPage:hover{
    padding-top: 4px;
    transition: all 0.3s ease-out;
    /* background-color:whitesmoke; */
    /* background-color: #0b7def; */
    background-color:  #EFD3D2;

    border-radius: 5px;
    border: 1px  #EFD3D2;
    color:whitesmoke;
    
}

.responsiveVolumeLashExtensionBelowHeadingList-eyelashServiceExtensionPage{
  
}
  .EyelashExtensionServicePageFold2Container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    background-color: white;
    margin-top: 5px;
    
  }

  .WaxingServiceInfo-eyelashExtensionpage{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 50px;
    position: relative;
    right: 60px;
    margin-bottom: 10px;
  }

  .WaxingServiceHeading-eyelashExtensionpage{
    width: 100%;
    height: 100px;
    font-size: 28px;
    /* font-family: 'Bebas Neue', cursive; */
    font-family: "Montserrat", sans-serif;
    /* background-color: rgb(232, 106, 167); */
    text-shadow: 4px 4px 8px #f5f5f542;
    color: black;
    /* padding-left: 70px; */
    font-weight: 500;
    position: relative;
    top: -20px;
  }

  .WaxingServiceInfoBelowHeading-eyelashextensionServicePage{
 
    font-size: 15px;
  
    font-family: "Montserrat", sans-serif;
  
    font-weight: 120;
   
    width: 500px;
    text-align: left;
    padding-right: 00px;
   
    text-shadow: 4px 4px 8px #f5f5f542;
    color: black;
    position: relative;
    top: -70px;
   
  }

  .WaxingServiceInfoBelowHeadingList-eyelashExtensionpage{
    list-style-type: circle;
    margin-top: 15px;
    padding-left: 40px;
    padding-bottom: 15px;
    text-decoration: underline;
    line-height: 30px;
  }

  .bookNowButtonWaxingService-foreyelashExtensionServicePage{
    position: relative;
    top: -10px;
    left: 120px;
  }

  .WaxingServicePageImg2{
    height: 500px;
    width: 450px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 10px;
    margin-left: 50px;
  }

  .EyelashExtensionServicePageFold3Container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    background-color: white;
    margin-top: 5px;
  }

  .EyelashExtensionServicePageHeading{
    text-align: center;
    font-size: 28px;
    color: white;
    background-color: black;
    font-family: "Montserrat", sans-serif;
    height: 70px;
  padding-top: 15px;
    font-weight: 50;
  }
.eyelashExtensionPageMobileNavbar{
  display: none;
}
  @media (max-width:1023px) {
    .EyelashExtensionServicePageHeading{
      height: 100px;
    }
.eyelashExtensionPageMobileNavbar{
  display: block;
  width: 100%;
  position: static;
}
.eyelashExtensionpageNavbar{
  display: none;
}
    .EyelashExtensionServicePageFold1Container{
       display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: auto;
    padding-top: 25px;
  }
.waxingServiceHeadingForeyelashextensionPage{
  text-align: center;
    position: relative;
    
    margin-top: 40px;
    width: 100%;
    padding-left: 164px;
}
  .KeratinlashLiftServiceHeading{
    text-align: center;
    position: relative;
    
    margin-top: 40px;
    width: 100%;
  }
    .EyelashExtensionServicePageImg1{
      height:auto;
    width: 300px; 
    margin-top: 40px;
    }
    .forResponsiveThisClassForKeratinlashLiftServiceImg{
      height:auto;
      width: 300px; 
      
    }
    
    .KeratinlashLiftServiceInfoBelowHeading-eyelashExtensionServicePage{
    padding-left: 3px;
   width: 85%;

    }
    .KeratinlashLiftServiceInfo{
      margin-left: 0px;
    }
    .aboveKeratinlashLiftServiceInfoBelowHeadingList{
      text-align: center;
      margin-left: 17px;
      width: 100%;
    }
    .KeratinlashLiftServiceInfoBelowHeadingList1{
      padding-left: 46px;
      width: 320px;

    }
    .belowKeratinlashLiftServiceInfoBelowHeadingList{
      padding-left: 31px;
      width: 113%;
      text-align: left;
    }
    .bookNowButtonKeratinlashLiftService{
      position: relative;
    
      top: -50px;
     
      left: 115px;
    }

    .EyelashExtensionServicePageFold2Container{
       display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column-reverse;
    height: auto;
    padding-top: 25px;
    }
    .WaxingServicePageImg2{
      height:auto;
    width: 300px; 
    margin-left: 0px;
    margin-top: 20px;
    }
    .WaxingServiceHeading{
      position: relative;
      top: 0px;
      margin-top: 20px;
      left: 199px;
      margin-bottom: 10px;
    }
    .WaxingServiceInfoBelowHeading-eyelashextensionServicePage{
      padding-left: 93px;
      width: 121%;
    }
    .WaxingServiceInfoBelowHeadingList-eyelashExtensionpage{
      padding-left: 17px;
    }
    .bookNowButtonWaxingService-foreyelashExtensionServicePage{
      top: -55px;
    left: 175px;
    }
    .EyelashExtensionServicePageFold3Container{
      display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    }
    .responsiveHeadingForClassicLashExtensionService{
      margin-bottom: 40px;
    }
    .responsiveKeratinlashLiftServiceInfoBelowHeading{
      width: 94%;
    padding-left: 33px;
    }
    .responsiveKeratinlashLiftServiceInfoBelowHeadingList{
      padding-left: 16px;
    }
    .responsiveVolumeLashExtensionServiceContainer{
      padding-top: 25px;
    }
    .responsiveHybridLashExtensionServiceHeading{
      width: 100%;
      left: 84px;
      text-align: center;
      margin-bottom: 54px;
    }
    .responsiveVolumeLashExtensionHeading{
      margin-bottom: 38px;
    }
    .responsiveVolumeLashExtensionBelowHeading{
      width: 93%;
      padding-left: 37px;
    }
  }
