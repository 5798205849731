* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.bodyOfMicrobladingPage {
  background-color: white;
}
.MainMicroBladingProductPageContainerAlsoContaining2ndFold {
  /* background-image: linear-gradient(to right top, #99ebf3, #7bcab7, #74a779, #758143, #74591c, #804714, #8a301b, #8f072b, #aa004c, #bd0078, #c101ad, #ad3ee9); */
  /* padding-left: 95px; */
  background-color: white;
}

.MainMicroBladingProductPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image:linear-gradient(to left bottom, #ca92b5, #be9cce, #a7a8e4, #86b6f1, #5ec3f4, #51c2f2, #41c0f0, #2cbfee, #47b0ee, #669fe7, #828dd9, #987ac4); ;  */
  height: 600px;
  /* width: 90%; */
}

.MicroBladingProductPageInfoBelowHeadingList {
  list-style-type: circle;
  margin-top: 15px;
  padding-left: 40px;
  padding-bottom: 15px;
  text-decoration: underline;
  line-height: 30px;
  /* color: black; */
}
.MicroBladingProductPageImg {
  height: 500px;
  width: 450px;
  border-radius: 20px;
  box-shadow: 0 5px 20px rgb(0, 0, 0);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
  margin-left: 0px;
}

.MicroBladingProductPageInfo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 150px;
  margin-bottom: 10px;
}

.MicroBladingProductPageHeading {
  width: 100%;
  height: 100px;
  font-size: 28px;
  /* font-family: 'Bebas Neue', cursive; */
  font-family: "Montserrat", sans-serif;
  /* background-color: rgb(232, 106, 167); */
  text-shadow: 4px 4px 8px #f5f5f542;
  color: black;
  /* padding-left: 70px; */
  font-weight: 500;
  position: relative;
  top: -20px;
}

.MicroBladingProductPageHeading2 {
  width: 800px;
  height: 100px;
  font-size: 80px;
  /* font-family: 'Bebas Neue', cursive; */
  /* font-family: Retina,Arial,Helvetica,sans-serif; */
  font-family: "Montserrat", sans-serif;

  font-weight: 100;
  color: whitesmoke;
  /* text-shadow: 4px 4px 8px #FF0000;; */
  text-shadow: 4px 4px 8px #2d6ee0;
}
.MicroBladingProductPageInfoBelowHeading {
  /* width: 750px; */
  font-size: 15px;
  /* font-family: 'Bebas Neue', cursive; */
  /* font-family: 'Oswald', sans-serif; */
  /* margin: 10px; */
  /* font-family: Escrow Condensed,Georgia,serif; */
  font-family: "Montserrat", sans-serif;

  font-weight: 120;
  /* text-shadow: 4px 4px 8px #2d6ee0;; */
  width: 500px;
  text-align: left;
  padding-right: 00px;
  /* background-color: rgb(232, 106, 167); */
  text-shadow: 4px 4px 8px #f5f5f542;
  color: black;
  /* text-shadow: 4px 4px 8px #FF0000;; */

  position: relative;
  top: -70px;
  /* margin-top: 90px; */
}

.bookNowButtonMicroBladingPage {
  /* margin-right: 200px; */

  position: relative;
  top: -10px;
  left: 120px;
}
.MainMicroBladingProductPage2ndContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 680px;
}

.testimonailWrapper {
  display: flex;
  flex-direction: column;
}

.MicroBladingProductPageInfo2 {
  margin-right: 40px;
}

.MicroBladingProductPageInfoBelowHeading2 {
  width: 700px;
  font-size: 20px;
  /* font-family: 'Bebas Neue', cursive; */
  /* font-family: 'Oswald', sans-serif; */
  font-family: "Montserrat", sans-serif;

  margin: 10px;
  font-weight: 900;
  color: whitesmoke;
  /* text-shadow: 4px 4px 8px #FF0000;; */
  font-family: "Oswald", sans-serif;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* width: 1096px; */
  width: 100%;

  height: auto;
}

.swiper-slide img {
  /* display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; */
}

.MicroBladingProductPage2ndImg {
  height: 600px;
  width: 450px;
  border-radius: 20px;
  box-shadow: 0 5px 20px rgb(0, 0, 0);
  /* border-radius: 20px; */
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  float: right;
  margin-top: 40px;
  margin-bottom: 60px;
}

.WhatPeopleAreSayingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: whitesmoke;
  padding-top: 20px;
  background-color: black;
  height: 120px;
}

.whatPeopleAreSaying {
  /* text-shadow: 4px 4px 8px #f5f5f542;; */
  color: whitesmoke;
  font-size: 16px;
  /* font-family: Escrow Condensed,Georgia,serif; */
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-weight: 100;
  /* text-shadow: 4px 4px 8px #09d3f2;; */
  /* margin-right: 110px;
    margin-left: 110px; */
  margin-bottom: 20px;
  width: 90%;
  position: relative;
  /* top: -80px; */
  /* margin-left: 30px; */
}
/* 
.peopleTestimonialContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    background-color: white;
    
    float:left;
    margin-left: 90px;
    padding-right: 0px;
} */

.personNameAndHisTestimonail {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 600px;
  height: 130px;
  border-radius: 20px;
  box-shadow: 0 5px 20px rgb(0, 0, 0);

  border: 1px solid #096ef2;
  color: #096ef2;
  box-sizing: border-box;
  margin-top: 0px;
  margin-left: 30px;
  margin-right: 30px;
}

.personNameAndHisTestimonail:nth-of-type(2){
    margin: 95px auto;
}


.testimonial {
  font-size: 14px;
  /* font-family: "Oswald", sans-serif;
  font-family: "Gelasio", serif; */
  /* font-style: italic; */
  font-family: "Montserrat", sans-serif;

  height: 90%;
  width: 100%;
  padding-left: 10px;
}

.personName {
  font-size: 14px;
  /* font-family: "Oswald", sans-serif;
  font-family: "Gelasio", serif; */
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  /* font-style: italic; */
  line-height: 1.5;
  /* margin: 0; */
  text-align: right;
  padding-right: 20px;
}

.bordered {
  border-left-width: 20px;
}

.ClientSelfieAnd2ndCallToAction {
  background-color: white;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-evenly;
}
.ClientSelfieImg {
  height: 600px;
  width: 450px;
  position: relative;
  left: 10px;
  border-radius: 20px;
  box-shadow: 0 5px 20px rgb(0, 0, 0);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
  /* left: 00px; */
  left: 130px;
  /* margin-right: 150px; */
}

.ClientSelfieBesideContent {
  font-size: 15px;
  width: 500px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  padding-top: 110px;
  position: relative;
  left: 250px;
}

.bookNowButton2MicroBladingPage {
  position: relative;
  top: 170px;
  right: 200px;
}

.MainMicroBladingProductPage2ndContainer .swiper-wrapper {
  /* height: 800px; */
  /* padding-bottom: 20px; */
}

@media (max-width:1023px) {
  .MainMicroBladingProductPageContainer{

    display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  }
  .MegaVolumeExtensionPageConatiner-openThroughNavbar-heading{
    font-size: 28px;
    height: 100px;
  }
  .MicroBladingProductPageImg {
    height: 300px;
    width: 300px;
    margin-top: 40px;
  }
  .MicroBladingProductPageInfo {
    margin-left: 0px;
  margin-bottom: 0px;
  width: auto;
 

  } 
  .MicroBladingProductPageHeading {
    font-size: 20px;
    top: 0px;
    width: 100%;
    text-align: center;
    /* height: auto; */
    margin-top: 20px;
  }
  .MicroBladingProductPageInfoBelowHeading {
    top: -40px;
    width: 80%;
    position: relative;
    left: 42px;
  }
  .MicroBladingProductPageInfoBelowHeadingList {
    text-align: left;
    padding-left: 15px;
  }
  .WhatPeopleAreSayingContainer {
    height: 140px;
  }
  .bookNowButtonMicroBladingPage{
    top: -30px;
    left: 100px;
  }
  .MainMicroBladingProductPage2ndContainer {
    height: auto;
    flex-direction: column;
  }
  .forMakingResponsivetheTestimonialForMicrobladingPage{
    height: 1000px;
  }
  .personNameAndHisTestimonail {
    width: auto;
    min-height: 220px;
  }
  .personNameAndHisTestimonail:nth-of-type(2){
    margin: 95px 30px;
}

.MicroBladingProductPage2ndImg{
  height: 300px;
  width: auto;
}
.ClientSelfieAnd2ndCallToAction {
  flex-direction: column;
}
.ClientSelfieImg {
  height: 300px;
  width: 300px;
  left: 40px;
}
.ClientSelfieBesideContent{
  left: 40px;
  width: 80%;
}
.bookNowButton2MicroBladingPage {
  
  position: static;
   
    display: flex;
    justify-content: center;
}
.allThingsContainerServiceSlider{
  margin-bottom: 28px;
}
}
