*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.mainFlexEyeLashServiceOfHomePageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 100%;
    background-color: whitesmoke;
}
.mainflexEyeLashServiceOfHomePageContainerHeading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 100px;
}

.mainflexEyeLashServiceOfHomePageContainerHeading1{
    width: 500px;
    height: 100px;
    font-size: 80px;
    font-family: 'Bebas Neue', cursive;
    font-weight: 900;
    
      text-shadow: 4px 4px 8px rgb(17, 204, 233);;  
   
color: black;
}

.mainflexEyeLashServiceOfHomePageContainerHeading2{
    font-size: 28px;
    width: 600px;
    font-family: 'Bebas Neue', cursive;
    padding-right: 30px;
    text-shadow: 4px 4px 8px rgb(17, 204, 233);;  
   
    color: black;
    font-weight: 900;
    /* text-shadow: 4px 4px 8px #FF0000;; */
}
.mainflexEyeLashServiceOfHomePageContainerHeading3{
    font-size: 28px;
    width: 450px;
    font-family: 'Bebas Neue', cursive;
    padding-left: 20px;
    text-shadow: 4px 4px 8px rgb(17, 204, 233);;  
   
    color: black;
    font-weight: 900;
}

.mainflexEyeLashContainerServiceOfHomePageHeading2ndPart{
    font-size: 28px;
    font-family: 'Bebas Neue', cursive;
    padding-right: 30px;
    /* color: whitesmoke; */
padding-left: 15px;
    font-weight: 900;
    text-shadow: 4px 4px 8px rgb(17, 204, 233);;  
   width: 400px;
    color: black;
}

.mainflexEyeLashContainerServiceOfHomePageHeading3rdPart{
    font-size: 28px;
    font-family: 'Bebas Neue', cursive;
    padding-left: 40px;
    /* color: whitesmoke; */

    font-weight: 900;
    text-shadow: 4px 4px 8px rgb(17, 204, 233);;  
   width: 400px;
    color: black;

}

.mainflexEyeLashContainerServiceOfHomePageHeading4thPart{
    font-size: 28px;
    font-family: 'Bebas Neue', cursive;
    
    /* color: whitesmoke; */
padding-left: 80px;
    font-weight: 900;
    text-shadow: 4px 4px 8px rgb(17, 204, 233);;  
   width: 300px;
    color: black;
}
.mainflexEyeLashContainerServiceOfHomePageHeading5{
    font-size: 28px;
    width: 500px;
    font-family: 'Bebas Neue', cursive;
    padding-left: 150px;
    text-shadow: 4px 4px 8px rgb(17, 204, 233);;  
   
    color: black;
}

.rightImgEyeLashServiceOfHomePageFlexContainer{
    height: 500px;
    width: 550px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    margin-left: 100px;

}