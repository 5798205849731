.checkoutProduct{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 10px solid #EFD3D2;
padding: 20px;
margin: 20px;
border-radius: 5px;

}

.checkoutProductImg{
    height: 300px;
    width:222px ;
    border-radius: 20px;
  box-shadow: 0 5px 20px rgb(0, 0, 0);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.checkoutProductInfo{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 40px;
}

.checkoutProductTitle{
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}

.checkoutProductPrice{
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}