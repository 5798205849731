/* #app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
} */

/* body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
} */

.BodyOfHomepage{
    background-color:   #EFD3D2;
   
}
.swiper {
  width: 100%;
  height: 80%;
}
.NavBarPositionOnHomepage{
  position: sticky;
  top: 0;
  z-index: 100;
}
.swiper-slide {
  /* width: 1096px; */
  width: 100%;
/* padding-top: 50px; */
  height: 80%;
}

.swiper-slide img {
  /* display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; */
}
.mobileNavbarDivForHomepage{
  display: none;
}
@media (max-width: 1023px){
  .swiper-slide {
    /* width: 1096px; */
    width: 100%;
  /* padding-top: 50px; */
    height: 50%;
  }
.NavBarPositionOnHomepage{
  display: none;
}
.mobileNavbarDivForHomepage{
  display: block;
  width: 100%;
  position: static;
  top: 0;
  z-index: 100;
}
  .swiper {
    width: 100%;
    height: 50%;
  }
  .BodyOfHomepage{
    width: 100vw;
    height: auto;
  }
 
  .forHeightInResponsive{
    height: 224px !important;
  }

  .forHeightInResponsive .swiper-wrapper{
    height: 224px !important;
  }


}
