*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main-heading-MyAccoridion{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border: 1px solid white;
    border-radius: 5px;
    margin: 10px;

    background-color:  #EFD3D2;

}
.MyAccordion-Item1{
    font-family: 'Montserrat',sans-serif;
    padding: 10px;
    font-size: 16px;
    margin: 10px;

}
.main-heading-MyAccoridion:hover{
    background-color: #e7b5b3;
}
.MyAccordion-Item2{
    background-color: #e7b5b3;
    padding: 5px;
    border: 1px solid white;
    border-radius: 5px;
    margin: 20px;
    line-height: 30px;
}
.MyAccordion-Item2Child{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}