
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.VolumeExtensionPageConatiner-openThroughNavbar-heading{
    text-align: center;
    font-size: 28px;
    color: white;
    background-color: black;
    font-family: "Montserrat", sans-serif;
    height: 70px;
  padding-top: 15px;
    font-weight: 50;
  }
  .VolumeExtensionPageConatiner-openThroughNavbar-VolumeByAnnosuneHeading{
   
    /* font-family: Retina,Arial,Helvetica,sans-serif; */
    font-family: 'Montserrat',sans-serif;

    font-weight: 700 ;
   position: relative;
   /* right: 350px; */
   width: 100%;
   color: black; 
   font-size: 44px;
   top: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   /* font-family: 'Bebas Neue', cursive; */
text-transform: uppercase;
/* text-shadow: 4px 4px 8px #4baeec85; */
    position: relative;
    top: 70px;
    padding-bottom: 10px;
}
.HybridsByannosuneContainer-forVolumeEyelashExtensionpage{
    height: 444px;
margin-top: 100px;
}
.backgroundColorLineFor-VolumeExtensionPageConatiner{
    background-color:  #EFD3D2;
    height: 5px;
    margin-top: 60px;
}

@media (max-width:1023px) {
    .VolumeExtensionPageConatiner-openThroughNavbar-heading{
        font-size: 24px;
        height: 62px;
    }
    .VolumeExtensionPageConatiner-openThroughNavbar-VolumeByAnnosuneHeading{
        font-size: 30px;
        text-align: center;
    }
    .HybridsByannosuneContainer-forVolumeEyelashExtensionpage{
        height: auto;
    }
    .responsiveSwiperForVolumeExtensionpage{
        height: 931px;
        width:  auto;
    }
    .backgroundColorLineFor-VolumeExtensionPageConatiner{
        margin-top: 0px;
    }
}