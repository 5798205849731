*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.mainArtistsContainer{
    height: 100%;

    height: 500px;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.headingArtists{
       color: black;
    /* top: 10%;
    left: 50%; */
    margin: 0 auto;
    /* margin-bottom: 40px; */
   /* font-family: 'Bebas Neue', cursive; */
 font-size: 32px;
 font-weight: 100;
 /* font-family: Retina,Arial,Helvetica,sans-serif; */
 font-family: 'Montserrat',sans-serif;

 /* text-shadow: 4px 4px 8px #4baeec85; */
position: relative;
top: 20px;
padding-bottom: 60px;

}

.AnnosuneANdJackiContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.katelynContainer{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper {
    width: 100%;
    height: 100%;

  }
  
  .swiper-slide {
      width: 1000px;
      height: auto;
      display: flex;
    justify-content: center;
    align-items: center;
    
  
    
  }
  
  .swiper-slide img {
    /* display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; */
  
  }

   @media (max-width:1023px){
    .headingArtists{
    
      text-align: center;

    }
    .mainArtistsContainer{
      padding-top: 80px;
      height: auto;
    }
  }