*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
}

/* body{
    background-image:linear-gradient(to left bottom, #ca92b5, #be9cce, #a7a8e4, #86b6f1, #5ec3f4, #51c2f2, #41c0f0, #2cbfee, #47b0ee, #669fe7, #828dd9, #987ac4); ;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

} */

.card{
    position: relative;
    width :600px;
    height:350px;
    /* background-image:linear-gradient(to left bottom, #ca92b5, #be9cce, #a7a8e4, #86b6f1, #5ec3f4, #51c2f2, #41c0f0, #2cbfee, #47b0ee, #669fe7, #828dd9, #987ac4); ; */
    border-radius: 20px;
    background-image: linear-gradient(to right top, #99ebf3, #7bcab7, #74a779, #758143, #74591c, #804714, #8a301b, #8f072b, #aa004c, #bd0078, #c101ad, #ad3ee9);
    display: flex;
    align-items: center;
    transition: 0.5s;
}

.card .circle{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    border-radius: 20px;
overflow: hidden;
}

.card .circle::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image:linear-gradient(to left bottom, #ca92b5, #be9cce, #a7a8e4, #86b6f1, #5ec3f4, #51c2f2, #41c0f0, #2cbfee, #47b0ee, #669fe7, #828dd9, #987ac4); ;
clip-path: circle(120px at center);
transition :0.5s;

}

.card:hover .circle:before{
    clip-path: circle(400px at center);
    background-image:linear-gradient(to left bottom, #ca92b5, #be9cce, #a7a8e4, #86b6f1, #5ec3f4, #51c2f2, #41c0f0, #2cbfee, #47b0ee, #669fe7, #828dd9, #987ac4); ;
}

.card img{
   position:absolute; 
   top: 50%;
   left: 50%;
   transform: translate(-50%,-50%);
   height: 300px;
   pointer-events: none;
   transition: 0.5s;
}

.card:hover img{
    left: 72%;;
    height: 500px;
}

.card .content{
    position: relative;
    width: 50%;
    left :20%;
    padding: 20px 20px 20px 40px;
    transition: 0.5s;
opacity: 0;
visibility: hidden;
}

.card:hover .content{
left:0;
opacity: 1;
visibility: visible;
}

.card .content h2{
    color:whitesmoke;
    text-transform: uppercase;
    font-size: 3em;
    line-height: 1em;
}

.card .content p{
    color:whitesmoke;
    font-size: 1.2em;
    font-weight: 700;
}

@media (max-width:991px){
   .card{
    width: auto;
    max-width: 350px;
    align-items: flex-start;
    margin: 10px;
   } 

   .card:hover{
    height: 600px;

   }
   .card .content{
    width: 100%;
    left: 0;
    padding: 30px;

   }
   .card:hover img{
    top: 70%;
    left :50%;
    height: 300px;

   }
}

@media (max-width:420px){
    .card .content{
      
        padding: 20px;
    
       }
}