*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.main-heading-MyAccoridionChildsChild{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border: 1px solid white;
    border-radius: 5px;
    margin: 10px;
}

.MyAccordion-Item1ChildsChild{
    font-family: 'Montserrat',sans-serif;
    padding: 10px;
    font-size: 16px;
    margin: 10px;
}

.main-heading-MyAccoridionChildsChild:hover{
    background-color: #e7b5b3;
}