*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

 .servicesMainContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    
    
} 

.servicesHeading{
    font-size: 40px;
    color: whitesmoke;
    font-family: 'Merriweather', serif;
    height :100px;
    padding-top: 20px;
    padding-left: 30px;
    
    
    
}









.smileTherapySection{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 900px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.smileTherapySectionimage{
    height: 300px;
    width: 300px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}