*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    }
    
    .InLeiEyelashLiftAndFillersServiceContainer{
       
    display: flex;
    justify-content: center;
    align-items: center;
     height: 550px;
     background-color: white;
    }
    
    .InLeiEyelashLiftAndFillersServiceCard{
      
        border-radius: 20px;
        box-shadow: 5px 5px 15px rgb(0, 0, 0);
        border-radius: 20px;
        backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.25);
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-left: 1px solid rgba(255, 255, 255, 0.5);
    
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 80%;
    
    }
    
    .productInLeiEyelashLiftAndFillersServiceHeading{
    font-family: 'Montserrat',sans-serif;
        margin-top: 10px;
    }
    /* .adhesiveWipesCard:hover{
    
        background-color:rgb(255, 255, 255);
    } */
    
    /* .adhesiveWipesCard img{
        border: solid 2px black;
    } */
    
    .imgFolderInLeiEyelashLiftAndFillersService{
        overflow: hidden;
        height: 300px;
        
     }
     .InLeiEyelashLiftAndFillersServiceImg{
        width:300px;
        height:300px;
        transition: 0.3s all linear;
     }
    
    
    
     .InLeiEyelashLiftAndFillersServiceImg:hover{
    
    
        transform: scale(1.2);
        
     }