*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    }
    
    .NewProductsContainer{
      
    display: flex;
    justify-content: center;
    align-items: center;
     height: 550px;
     
    }
    
    .NewProductsCard{
      
        border-radius: 20px;
        box-shadow: 0 15px 35px rgb(0, 0, 0);
        border-radius: 20px;
        backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.25);
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-left: 1px solid rgba(255, 255, 255, 0.5);
    
        display: flex;
        justify-content:flex-start;
        align-items: center;
        flex-direction: column;
        height: 80%;
    
    }
    
    .Newproduct2Heading{
        font-family: 'Montserrat',sans-serif;
        font-size: 22px;

    }
    /* .disposableApplicatorCard:hover{
    
    } */
    

    .imgFolder{
        overflow: hidden;
        height: 300px;
        
     }
     .NewProductsImg{
        width:300px;
        height:270px;
        transition: 0.3s all linear;
        border-radius: 20px;

     }


    
     .NewProductsImg:hover{
    
    border-radius: 50%;

        /* transform: scale(1.1); */
        
     }

     .NewaddToCartButton{
        height: 35px;
    width:170px;
    border-radius: 5px;
    font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    padding-top: 4px;
    box-shadow: black 5px 5px;
    cursor: pointer;
    border-color: #EFD3D2;
    background-color: #EFD3D2;
    margin-top: 7px;
    color: black;
padding-bottom: 15px;

     }
.buyNowButtonForIndividualProducts{
    position: relative;
        bottom: 8px;
     
}
     .Newproduct2Price{
        position: relative;
        bottom: 19px;
        font-size: 20px;
     }
     .Newproduct2Heading{
        position: relative;
        bottom: 16px;
     }
     .NewaddToCartButton:hover{
        padding-top: 4px;
    transition: all 0.3s ease-out;
    /* background-color:whitesmoke; */
    /* background-color: #0b7def; */
    background-color:  #EFD3D2;

    border-radius: 5px;
    border: 1px  #EFD3D2;
    color:whitesmoke; 
     }