.btnViewAllBlog{
    height: 30px;
    width:100px;
    border-radius: 5px;
    font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    padding-top: 2px;
    box-shadow: black 5px 5px;
    cursor: pointer;
    border-color:whitesmoke;;
    background-color:whitesmoke;
    margin-top: 20px;

}

.btnViewAllBlog:hover{
    padding-top: 2px;
    transition: all 0.3s ease-out;
    /* background-color: ; */
    background-color:  #EFD3D2;

    border-radius: 5px;
    border: 1px black;
    color: white;
    
}