*{
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
    
}

.VolumeLashExtensionCertificationCourseMainContainer{
    background-color: white;
    height: 640px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 350px; */
    width: 100%;
}

.VolumeLashExtensionCertificationCourseSection{
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 900px; */
    padding-top: 25px;
    margin-bottom: 10px;
}

.VolumeLashExtensionCertificationCourseimage{
    height: 500px;
    width: 500px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    margin-right: 80px;
}

.VolumeLashExtensionCertificationCourseHeading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;
    margin-bottom: 10px;
    padding-left: 00px;

}

.VolumeLashExtensionCertificationCourseSectionHeadingInfo{
    font-size: 32px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;
    margin-bottom: 10px;
    color: black;
    /* text-shadow: 4px 4px 8px  #ef7c8e66;; */
}

.VolumeLashCertificationCourseSectionInfo{
    font-size: 15px;
    font-family: 'Montserrat',sans-serif;
    color: black;
   
    width: 400px;
    margin-left: 20px;  
}

.BookNowButtonOfVolumeLashExtensioncourse{
    margin-right: 60px;
}

@media (max-width:1023px) {
    .VolumeLashExtensionCertificationCourseSection{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .VolumeLashExtensionCertificationCourseimage{
        width: 300px;
        height: auto;
        margin-top: 10px;
      margin-left: 73px;
    }
    .VolumeLashExtensionCertificationCourseSectionHeadingInfo{
        text-align: center;
        font-size: 28px;
        padding-left: 0px;
        width: 74%;
        margin-top: 10px;
    }

    .VolumeLashCertificationCourseSectionInfo{
        font-size: 14px;
width: 72%;
margin-left: 0px;
text-align: center;
    }
    .VolumeLashExtensionCertificationCourseMainContainer{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .BookNowButtonOfVolumeLashExtensioncourse{
        margin-right: 0px;
    }
    
}