*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.blogHeadingAndmainBlogContainer{
    height: 750px;
    background-color: white;
}
.mainBlogContainer{
display: flex;
justify-content: space-around;
align-items: center;
margin-top: 0px;
margin-bottom: 0px;

height: 700px;
background-color: white;
}


.blogHeading{
    color: black;
    /* font-family: 'Bebas Neue', cursive; */
    font-size: 32px;
    /* font-family: Retina,Arial,Helvetica,sans-serif; */
    font-family: 'Montserrat',sans-serif;

    /* text-shadow: 4px 4px 8px #4baeec; */
    font-weight: 900;
  text-align: center;
    padding-bottom: 10px;
    /* width: 400px; */
    background-color: white;
    position: relative;
    top: 20px;
    /* left: 60px; */
}

.BlogHeading2{
    color: black;
    /* font-family: 'Bebas Neue', cursive; */
    font-size: 24px;
    /* font-family: Escrow Condensed,Georgia,serif; */
    font-family: 'Montserrat',sans-serif;
margin-left: 40px;
    font-weight: 100;
    /* text-shadow: 4px 4px 8px #4baeec85; */

   
}
.viewAllBlogButton{
    margin-left: 40px;
}

.ReadMoreSmileTherapyCostInFresno{
    text-align: center;
    /* margin-bottom: 100px; */
    position: relative;
    bottom: 10px;
  
}
.forResponsiveToKeepBothLeftAndRightDown{
    display: flex;
}
.leftSection{
    width: 400px;
    /* margin-right: 20px; */
}

.CenterSection{
    margin-right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.RightSection{
margin-top: 20px;}
.leftImage{
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    position: relative;
bottom: 10px;

}
.RightImageOfBlog{
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
margin-left: 0px;
position: relative;
bottom: 10px;
}


.headingBelowImage{
    margin-top: 10px;
    color: black;
    /* font-family: 'Merriweather', serif; */
    font-size: 24px;
    /* font-family: Escrow Condensed,Georgia,serif; */
    font-family: 'Montserrat',sans-serif;

    font-weight: 100;
    /* text-shadow: 4px 4px 8px #4baeec85; */
}
.headingBelowRightImage{
    margin-top: 7px;
    color: black;
    /* font-family: 'Merriweather', serif; */
    font-size: 24px;
    /* font-family: Escrow Condensed,Georgia,serif; */
    font-family: 'Montserrat',sans-serif;

    font-weight: 100;
    /* text-shadow: 4px 4px 8px #4baeec85; */
    text-align: center;
}

.heading2BelowImage{
    margin-top: 0px;
    color: black;
    font-family: 'Merriweather', serif;
    font-size: 18px;
    /* font-family: Escrow Condensed,Georgia,serif; */
    font-family: 'Montserrat',sans-serif;

    font-weight: 100;
    /* text-shadow: 4px 4px 8px #4baeec85; */
}

.heading2BelowRightImage{
    margin-top: 0px;
    color: black;
    font-family: 'Merriweather', serif;
    font-size: 18px;
    /* font-family: Escrow Condensed,Georgia,serif; */
    font-family: 'Montserrat',sans-serif;

    font-weight: 100;
    /* text-shadow: 4px 4px 8px #4baeec85; */
    width: 400px;
    text-align: center;
}

@media (max-width:1023px){

    .blogHeadingAndmainBlogContainer{
        height: 550px;
    }

    .blogHeading{
        text-align: center;
    }

    .mainBlogContainer{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    align-items: flex-start;
    
    }

    .leftSection{
        width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .BlogHeading2{
        font-size: 14px;
        text-align: center;
        padding-top: 20px;
        margin-left: 0px;
        width: 99%;

    }
    
    .viewAllBlogButton{
        /* margin-left: 50px; */
        margin-left: 0px;
        margin-right: 10px;
    }
    .forResponsiveToKeepBothLeftAndRightDown{
        display: flex;
        margin-top: 40px;
    }

    .CenterSection , .RightSection{
        display: flex;
        flex-direction: column;
        width: 50%;
        text-align: center;
        justify-content: center;
    align-items: center;
    margin-right: 0px;
    margin-top:0px ;

    }

    .leftImage{
        width: 150px;
        height: auto;
    }
    .RightImageOfBlog{
        width: 150px;
        height: auto;
    }
    .headingBelowImage ,.heading2BelowImage , .headingBelowRightImage , .heading2BelowRightImage{
        font-size: 14px;
        text-align: center;
        width: 97%;
    }
    .headingBelowRightImage {
        margin-bottom: 20px;
    }
    .headingBelowImage{
        margin-top: 5.5px;
        margin-bottom: 5.5px;
    }

    .btnReadMoreSmileTherapy{
        position: relative;
        top: 10px;
    }

    .heading2BelowRightImage{
width: 90%;
    }
}