.subtotalComponent{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    height:  100%;
    padding:  20px;
    background-color: whitesmoke;
    border-radius: 3px;
    border:  1px solid   #EFD3D2;
}

.proceedToCheckoutButton{
    height: 50px;
    width:170px;
    border-radius: 5px;
    font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    padding-top: 4px;
    box-shadow: black 5px 5px;
    cursor: pointer;
    border-color: #EFD3D2;
    background-color: #EFD3D2;
    margin-top: 20px;
    color: black;
}

.proceedToCheckoutButton:hover{
    padding-top: 4px;
    transition: all 0.3s ease-out;
    /* background-color:whitesmoke; */
    /* background-color: #0b7def; */
    background-color:  #EFD3D2;

    border-radius: 5px;
    border: 1px  #EFD3D2;
    color:whitesmoke;
    
}
