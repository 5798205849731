*{
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
    
}

.ArtistsPageMainContainer{
    
    
}

.AnnouseContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
    background-image:linear-gradient(to left bottom, #ca92b5, #be9cce, #a7a8e4, #86b6f1, #5ec3f4, #51c2f2, #41c0f0, #2cbfee, #47b0ee, #669fe7, #828dd9, #987ac4); ;
}

.AnnosuneInfoSection{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.AnnosuneName{
    font-size: 20px;
    font-family: 'Merriweather', serif;
}

.phoneNumber{
    font-size: 15px;
    font-family: 'Merriweather', serif;
}

.AnnosuneInfo{
    font-size: 18px;
    font-family: 'Merriweather', serif;
    width: 700px;
    margin-left: 20px; 
}

.AnnosuneImg{
     height: 550px;
    width: 450px; 
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}
