*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.mainFlexEyeLashContainer{
    /* display: flex;
    justify-content: center;
    align-items: center; */
    height: 850px;
    width: 100%;
    position: relative;
    /* top: -200px; */
    /* background-image:linear-gradient(to left bottom, #ca92b5, #be9cce, #a7a8e4, #86b6f1, #5ec3f4, #51c2f2, #41c0f0, #2cbfee, #47b0ee, #669fe7, #828dd9, #987ac4); ; */
    /* background-image: linear-gradient(to right top, #99ebf3, #7bcab7, #74a779, #758143, #74591c, #804714, #8a301b, #8f072b, #aa004c, #bd0078, #c101ad, #ad3ee9);
     */
}


.mainFlexEyeLashContainerContent1{
    /* font-family: Graphik Wide,arial,sans-serif;; */
    font-family: 'Montserrat', sans-serif;

    top: 200px;
    left: 40px;
    font-size: 40px;
    color: whitesmoke;
    font-weight: 100;
    text-transform: uppercase;
    position: absolute;
}

.mainFlexEyeLashContainerContent2{
    /* font-family: Graphik Wide,arial,sans-serif;; */
    font-family: 'Montserrat', sans-serif;

    top: 260px;
    left: 40px;
    font-size: 100px;
    color: whitesmoke;
    font-weight: 1000;
    text-transform: uppercase;
    position: absolute;
    width: 800px;
    height: 500px;
}
.buttonForThisSlider{
    position: absolute;
    /* left: -400px; */
    top: 560px;
    left: 50px;
}

.ImgAndButtonContainer{
    display: flex;
    flex-direction: column;
    position: relative;
}

.mainflexEyeLashContainerHeading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding-bottom: 100px; */
padding-bottom: 200px;
}
/* .rightImgFlexContainer{
   
    height: 800px;
    width: 700px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);

} */
.mainflexEyeLashContainerHeading1{
    /* width: 100%; */
    height: 100px;
    font-size: 80px;
    /* font-family: 'Bebas Neue', cursive; */
    font-family: Retina,Arial,Helvetica,sans-serif;
    font-weight: 400;
    color: whitesmoke;
    /* text-shadow: 4px 4px 8px #FF0000;; */
    text-shadow: 4px 4px 8px #2d6ee0;;

    text-transform: uppercase;
    padding-left: 50px;

    @media (max-width:1024){
        
    }
}



.mainflexEyeLashContainerHeading2ndPart{
    /* width: 800px; */
    width: 100%;
    height: 100px;
    padding-left: 170px;
    font-size: 80px;
    /* font-family: Retina,Arial,Helvetica,sans-serif; */
    /* font-family: Retina,Arial,Helvetica,sans-serif; */
    font-family: Retina,Arial,Helvetica,sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    color: whitesmoke;
    /* text-shadow: 4px 4px 8px #FF0000;; */
    text-shadow: 4px 4px 8px #2d6ee0;;

}
.mainflexEyeLashContainerHeading2{
    /* padding-top: 100px; */
    width: 100%;

    font-size: 80px;
    /* font-family: 'Bebas Neue', cursive; */
    font-family: Retina,Arial,Helvetica,sans-serif;
    padding-left: 340px;

    /* padding-right: 30px; */
    color: whitesmoke;
    font-weight: 400;
    /* text-shadow: 4px 4px 8px #FF0000;; */
    text-shadow: 4px 4px 8px #2d6ee0;;

    text-transform: uppercase;

}

.mainflexEyeLashContainerHeading3{
    font-size: 40px;
    /* width: 700px; */
    width: 100%;
    padding-left: 120px;
    /* font-family: 'Bebas Neue', cursive; */
    /* padding-right: 60px; */
    color: whitesmoke;
    /* font-weight: 900; */
    /* text-shadow: 2px 2px 8px #FF0000;; */
    text-shadow: 4px 4px 8px #2d6ee0;;

    /* text-transform: uppercase; */
    padding-top: 30px;
    font-family: Escrow Condensed,Georgia,serif;


    
    

}

.mainflexEyeLashContainerHeading4{
    font-size: 40px;
    /* width: 700px; */
    width: 100%;
    padding-left: 442px;

    /* font-family: 'Bebas Neue', cursive; */
    /* padding-left: 230px; */
    color: whitesmoke;
    /* font-weight: 100; */
    /* text-shadow: 2px 2px 8px #FF0000;; */
    text-shadow: 4px 4px 8px #2d6ee0;;

    text-transform: uppercase;
    padding-top: 10px;
    font-family: Escrow Condensed,Georgia,serif;
}

.mainflexEyeLashContainerHeading5{
    font-size: 28px;
    /* width: 700px; */
    width: 100%;

    /* font-family: 'Bebas Neue', cursive; */
    padding-left: 465px;
    color: whitesmoke;
    /* text-shadow: 2px 2px 8px #FF0000;; */
    text-shadow: 4px 4px 8px #2d6ee0;;

    text-transform: uppercase;
    padding-top: 10px;
    font-family: Escrow Condensed,Georgia,serif;
}

.rightImgEyeLashFlexContainer{
  
    height: 800px;
    width: 700px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);

}

.buttonCSSEyelashesHome{
    padding-left: 35px;
}

@media (max-width:1023px) {
    .ImgAndButtonContainer{
        height: 224px;
        width: 100%;
    }
    .mainFlexEyeLashContainer{
    height: 224px;
    width: 100%;
    }
    .ImgAndButtonContainer .mainFlexEyeLashContainerContent1{
        top: 50px;
        font-size: 28px;
    }
    .ImgAndButtonContainer .mainFlexEyeLashContainerContent2{
        top: 85px;
        font-size: 28px;
    }
    .buttonForThisSlider {
        position: absolute;
    /* left: -400px; */
    top: 141px;
    left: 43px;
    
}

}