*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.CoursesMainEverythingContainer{
    background-color: white;
    width: 100%;
    height: 850px;
    display: flex;
    justify-content: space-between;
    
}

.CoursesMainSection{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    z-index: 10;
}

.CoursesMainImgContaine{
    height: 850px;
    width: auto;
}

.CoursesMainSectionLine1{
font-family: 'Montserrat', sans-serif;
position: relative;
    top: 200px;
    left: 60px;
    font-size: 40px;
    color: black;
    font-weight: 100;
    text-transform: uppercase;
}

.CoursesMainSectionLine2{
    font-family: 'Montserrat', sans-serif;
position: relative;
    top: 220px;
    left: 60px;
    font-size: 100px;
    color: black;
    font-weight: 100;
    text-transform: uppercase;
}
.checkoutOurcoursesNowButtonSlider3homepage{
height: 50px;
width:350px;
border-radius: 13px;
font-family: 'Anton', sans-serif;
font-family: 'Bebas Neue', cursive;
font-size: 31px;
padding-top: 4px;
box-shadow: lightskyblue 5px 5px;
cursor: pointer;
border-color:whitesmoke;
background-color: #EFD3D2;
margin-top: 20px;
position: relative;
top: 200px;
left :67px;
}
.checkoutOurcoursesNowButtonSlider3homepage:hover{
    padding-top: 4px;
    transition: all 0.3s ease-out;
    /* background-color:whitesmoke; */
    background-color: #0b7def;
    border-radius: 13px;
    border: 1px  #EFD3D2;
    color:whitesmoke;
}

@media (max-width:1024px) {
    .CoursesMainImgContainer{
        position: absolute;
        height: 224px;
        width: 215px;
        right: 0;
    }
    .CoursesMainEverythingContainer{
        height: 224px !important;
       
    }
    .CoursesMainSectionLine1{
        top: 21px;
        font-size: 20px;
        left: 48px;
        width: 100px;
    }
    .CoursesMainSectionLine2{
        left: 50px;
        top: 21px;
        font-size: 20px;
        width: 100px;
    }
    .checkoutOurcoursesNowButtonSlider3homepage{
        height: 35px;
        width: 220px;
        font-size: 20px;
        position: relative;
        top: 20px;
        left: 48px;
    }
    
}