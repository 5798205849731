*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.coursesMainContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.CoursesPageHeading{
    text-align: center;
    font-size: 28px;
    color: white;
    background-color: black;
    font-family: "Montserrat", sans-serif;
    height: 70px;
  padding-top: 15px;
    font-weight: 50;
  }