* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .bodyOfSignUpPage{
    background-color:  #EFD3D2;
height: 745px;
width: 100%;
display: flex;
justify-content:flex-start;
align-items: center;
flex-direction: column;
padding-top: 50px;
  }

  .cardOfSignUpPage{
    box-shadow: 0 15px 35px rgb(0, 0, 0);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  background-color: white;
  height: 600px;
  width: 400px;
  display: flex;
justify-content:flex-start;
align-items: center;
flex-direction: column;
margin-top: 30px;
  }

  .logoImageOfSignUp{
margin-top: 30px;

  }

.success-msg{
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  background-color: #EFD3D2;
text-align: center;
}
  .SignUpPageHeading{
    text-align: left;
    font-size: 32px;
  font-family: "Montserrat", sans-serif;
  
  margin-top: 30px;
  }

  .FullNameHeading{
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    padding-right: 240px;
  margin-top: 30px;
  margin-bottom: 10px;
  }
  .emailHeading{
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    padding-right: 240px;
  margin-top: 30px;
  margin-bottom: 10px;
  }

  .FullNameInput{
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    border-radius: 5px;
    /* border: 1px solid transparent;
    border-bottom-color: #E8B4B8; */
  height: 40px;
  width: 340px;
  padding-left: 10px;
  color: black;
  }
  .emailInput{
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    border-radius: 5px;
    /* border: 1px solid transparent;
    border-bottom-color: #E8B4B8; */
  height: 40px;
  width: 340px;
  padding-left: 10px;
  color: black;
  /* text-decoration: underline; */
  }
 
  .firstInput{
    margin-top: 10px;
    
  }

  .SignUpButton{
    margin-top: 10px;
  }

  .SignUpButtonProcess{
    height: 50px;
    width:340px;
    border-radius: 5px;
    font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    padding-top: 4px;
    padding-right: 2px;
    box-shadow: black 5px 5px;
    cursor: pointer;
    border-color: #EFD3D2;
    background-color: #EFD3D2;
    margin-top: 20px;
    color: black;
  }

  .SignInButtonProcess:hover{
    padding-top: 4px;
    transition: all 0.3s ease-out;
    /* background-color:whitesmoke; */
    /* background-color: #0b7def; */
    background-color: #EFD3D2;

    border-radius: 5px;
    border: 1px  #EFD3D2;
    color:whitesmoke;
    
  }
  
  