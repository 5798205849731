.MainConatinerProductDesc{
    background-color: white;
    height: 100%;
    width: 100%;
    /* display: flex; */

}

.ProductDescLeftAndRight{
    display: flex;
    justify-content: flex-start;
}
.ProductDescHeading{
    text-align: center;
    font-size: 28px;
    color: white;
    background-color: black;
    font-family: "Montserrat", sans-serif;
    height: 70px;
    padding-top: 15px;
    font-weight: 50;

}
.ProductDescLeft{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.ProductDescImgDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EFD3D2;
    border-radius: 20px;
box-shadow: 0 5px 20px rgb(0, 0, 0);
border-radius: 20px;
backdrop-filter: blur(10px);
border: 1px solid rgba(255, 255, 255, 0.25);
border-top: 1px solid rgba(255, 255, 255, 0.5);
border-left: 1px solid rgba(255, 255, 255, 0.5);
position: relative;
bottom: 10px;
    height: 400px;
    width:500px;
    margin-left: 100px;
    margin-top: 100px;
}
.ProductDescImg{
border-radius: 20px;
box-shadow: 0 5px 20px rgb(0, 0, 0);
border-radius: 20px;
backdrop-filter: blur(10px);
border: 1px solid rgba(255, 255, 255, 0.25);
border-top: 1px solid rgba(255, 255, 255, 0.5);
border-left: 1px solid rgba(255, 255, 255, 0.5);
position: relative;
bottom: 10px;
height: 300px;
width: 300px;
background-color: white;
}
.ProductDescInfoContent{
    margin-left: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 100px;
    width: 73%;
    
}

.ProductDescInfoContentHeading{
    font-size: 24px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;
    /* width: 100%; */
    /* margin-left: 50px; */
    /* text-align: center; */
    margin-bottom: 20px;
    width: auto;
}

.ProductDescInfoContentDescDiv{
    /* width: 1000px; */
    width: 70%;
}

.ProductDescInfoContentDescription{
font-size: 15px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;
    width: 100%;
    line-height: 30px;
    /* margin-left: 50px; */
    /* margin-top: 20px; */
    /* text-align: center; */
    margin-bottom: 20px;
}
.ProductDescInfoContentButtonDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ProductDescInfoContentButton{
height: 50px;
width:500px;
border-radius: 5px;
font-family: 'Anton', sans-serif;
font-family: 'Bebas Neue', cursive;
font-size: 25px;
padding-top: 1px;
box-shadow: black 5px 5px;
cursor: pointer;
border-color: whitesmoke;
background-color: #EFD3D2;
margin-bottom: 20px;
;
}

.ProductDescInfoContentButton:hover{
    padding-top: 1px;
    transition: all 0.3s ease-out;
    /* background-color:  #EFD3D2; */
    
    border-radius: 5px;
    border-color:  black;
    /* color:rgb(6, 180, 254); */
}

.ProductDescInfoContentButtonAddToCart{
    height: 50px;
    width:500px;
    border-radius: 5px;
    font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 25px;
    padding-top: 4px;
    box-shadow: lightskyblue 5px 5px;
    cursor: pointer;
    border-color: #EFD3D2;;
    background-color: #EFD3D2;
    margin-top: 20px;
}

.ProductDescInfoContentButtonAddToCart:hover{
    padding-top: 1px;
    transition: all 0.3s ease-out;
    /* background-color:  #EFD3D2; */
    
    border-radius: 5px;
    border-color:  black;
    /* color:rgb(6, 180, 254); */
}

.ProductDescRight{
    width: 900px;
    height: 500px;
    border: black 4px double;
    border-radius: 10px;
    position: relative;
    /* top: 100px; */
    right: 14px;

}
.YourSubtotalProductDesc{
    /* position: relative;
    top: 100px;
    right: 100px; */
    border: 1px black solid;
    border-radius: 5px;
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height:  100px; */
    padding:  20px;
    background-color: whitesmoke;
   border-radius: 3px;
    border:  1px solid   #EFD3D2;
}