*{
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
    
}

.LashLift_TintCourseMainContainer{
    height: 640px;
    background-color: white;
    /* height: 350px; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.LashLift_TintCourseSection{
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 900px; */
    padding-top: 25px;
    margin-bottom: 10px;
}

.LashLift_TintCourseimage{
    height: 500px;
    width: 500px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    /* margin-right: 160px; */
    position: relative;
    right: 100px;

}

.LashLift_TintCourseHeading{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    font-size: 16px;
    /* font-family: '', serif; */
    font-family: 'Montserrat',sans-serif;

    margin-bottom: 10px;
}

.LashLift_TintCourseSectionHeadingInfo{
    font-size: 32px;
    margin-right: 0px;
    margin-bottom: 10px;
    color: black;
    /* text-shadow: 4px 4px 8px #ef7c8e66;; */
    /* padding-left: 60px; */
}

.LashLift_TintCourseSectionInfo{
    
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
font-weight: 100;
    width: 400px;
    color: black;
    /* margin-left: 100px; */
}

.LashLiftTintButton{
    position: relative;
    left: 60px;
}
@media (max-width:1023px) {
    .LashLift_TintCourseSection{
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .LashLift_TintCourseimage{
        width: 300px;
        height: auto;
        margin-top: 10px;
        position: relative;
        left: 0px;

    }
    .LashLift_TintCourseSectionHeadingInfo{
        text-align: center;
        font-size: 28px;
        padding-left: 0px;
        width: 100%;
        margin-top: 10px;
    }
    .LashLift_TintCourseSectionInfo{
font-size: 14px;
width: 72%;
margin-left: 54px;
text-align: center;
    }
.LashLiftTintButton{
    margin-left: 55px;
}

}