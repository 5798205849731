*{
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
    
}

.forHappyGivingMargin{
  margin-bottom: 20px;
}
.MobileNavbarArtistPage{
  background-color:  #EFD3D2;

}
.MobileNavbarArtistPage{
  display: none;
}
.ArtistsPageHeading{
    text-align: center;
    font-size: 28px;
    color: white;
    background-color: black;
    font-family: "Montserrat", sans-serif;
    height: 70px;
  padding-top: 15px;
    font-weight: 50;
  }

.AnnosuneFoldContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    background-color: white;
    padding-left: 220px;
  }
  
  .AnnosuneInfoBelowPhoneNumber{
   
    margin-top: 15px;
   
    padding-bottom: 15px;
   
    line-height: 30px;
    /* color: black; */
  }
  .AnnosuneImg1 {
    height: 500px;
    width: 400px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 10px;
    margin-left: 0px;
  }
  
  .AnnosuneInfo {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 100px;
/* position: relative; */
/* left: 210px; */
    margin-bottom: 10px;
  }
  
  .AnnosuneHeading {
    width: 100%;
   
    font-size: 28px;
    /* font-family: 'Bebas Neue', cursive; */
    font-family: "Montserrat", sans-serif;
    /* background-color: rgb(232, 106, 167); */
    text-shadow: 4px 4px 8px #f5f5f542;
    color: black;
    /* padding-left: 70px; */
    font-weight: 500;
    position: relative;
    
  }
  
 
  
  .AnnosuneInfoBelowHeading {
    /* width: 750px; */
    font-size: 15px;
   
    font-family: "Montserrat", sans-serif;
  
    font-weight: 120;
    /* text-shadow: 4px 4px 8px #2d6ee0;; */
    width: 500px;
    text-align: left;
    padding-right: 00px;
    /* background-color: rgb(232, 106, 167); */
    text-shadow: 4px 4px 8px #f5f5f542;
    color: black;
    /* text-shadow: 4px 4px 8px #FF0000;; */
  
    position: relative;
    
    /* margin-top: 90px; */
  }
  .annosunePhonenUmberArtistPage{
    font-size: 15px;
    text-align: left;
    text-shadow: 4px 4px 8px #f5f5f542;
   
    font-family: "Montserrat", sans-serif;
  }
  
  .bookNowButtonForAnnosune {
    /* margin-right: 200px; */
  
    position: relative;
    top: -10px;
    left: 80px;
 

  }

  .bookNowButtonOnArtistsForAnnosune{
    height: 50px;
    width:240px;
    border-radius: 5px;
    font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    padding-top: 4px;
    box-shadow: black 5px 5px;
    cursor: pointer;
    border-color: #EFD3D2;
    background-color: #EFD3D2;
    margin-top: 20px;
    color: black;

}

.bookNowButtonOnArtistsForAnnosune:hover{
    padding-top: 4px;
    transition: all 0.3s ease-out;
    /* background-color:whitesmoke; */
    /* background-color: #0b7def; */
    background-color:  #EFD3D2;

    border-radius: 5px;
    border: 1px  #EFD3D2;
    color:whitesmoke;
    
}
.AnnosuneServicesButtons{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.ClassicdByAnnosuneButtonOnArtistsPage{
  height: 50px;
    width:240px;
    border-radius: 5px;
    font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    padding-top: 4px;
    box-shadow: black 5px 5px;
    cursor: pointer;
    border-color: #EFD3D2;
    background-color: #EFD3D2;
    margin-top: 20px;
    color: black;
}


@media (max-width:1023px){
  .MobileNavbarArtistPage{
    display: block;
    width: 100%;
    position: static;
top: 0;
z-index: 100;
  }
  .AnnosuneFoldContainer {
    flex-direction: column;
    padding-left: 0px;
    height: auto;
  }
  .AnnosuneImg1 {
    height: 300px;
    width:auto;
    margin-top: 40px;
  }
  .AnnosuneInfo {
    /* margin-left: 0px; */
    justify-content: center;
    align-items: center;
    /* margin-left: 0px; */
    width: auto;
    margin-left: 0px;
    
  }
  .annosunePhonenUmberArtistPage{
   
    text-align: center;
  }
  .AnnosuneHeading {
    text-align: center;
    width: auto;
  
    margin-top: 20px;
  }
  .AnnosuneInfoBelowHeading {
    text-align: center;
    width: auto;
  }
 
  .AnnosuneInfoBelowPhoneNumber{
    text-align: center;
    position: relative;
    /* right: 77px; */
    width: 90%;
    left: 20px;
    
  }
  .bookNowButtonForAnnosune {
    top: 0;
    left: 0;
  }
  .AnnosuneServicesButtons{
    justify-content: center;
    flex-direction: column;
  }
  }

.loader {
  height: 444px;
  width: 100%;
  text-align: center;
}

.block-display {
  display: block;
}

.block-hidden {
  display: none;
}