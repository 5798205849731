.btn{
    height: 30px;
    width:100px;
    border-radius: 3px;
    font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    padding-top: 2px;
    /* padding-bottom: 4px; */
    box-shadow:  whitesmoke 5px 5px;
    cursor: pointer;
    border-color:black;
    /* background-color:rgba(236, 133, 245, 0.884);; */
    /* background-color: ; */
    background-color: whitesmoke;
text-align: center;
    color: black;
    margin-top: 10px;
    

}

.btn:hover{
    padding-top: 4px;
    transition: all 0.3s ease-out;
    /* background-color: whitesmoke; */
    border-radius: 3px;
    border: 1px black;
    /* color: whitesmoke; */
    background-color: whitesmoke;
    color: black;

}