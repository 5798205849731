.btnCheckAvailableDates{
    height: 50px;
    width:350px;
    border-radius: 13px;
    font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 31px;
    padding-top: 4px;
    box-shadow: lightskyblue 5px 5px;
    cursor: pointer;
    border-color:whitesmoke;
    background-color:  #EFD3D2;
    margin-top: 20px;

}

.btnCheckAvailableDates:hover{
    padding-top: 4px;
    transition: all 0.3s ease-out;
    /* background-color:whitesmoke; */
    background-color: #0b7def;
    border-radius: 13px;
    border: 1px  #EFD3D2;
    color:whitesmoke;
    
}

@media (max-width:1023px) {
    .btnCheckAvailableDates{
    height: 35px;
    width:140px;
    font-size: 20px;

    }
}