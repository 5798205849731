.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    /* width: 1096px; */
    width: 100%;
  background-color: white;
    height: 50%;
    /* padding-top: 50px; */
  }
  
  .swiper-slide img {
    /* display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; */
  }

  .HeadingOfCourseSwiper{
    
    margin-left: 2%;
    font-family: 'Montserrat',sans-serif;

    font-weight: 100;
   
   color: black; 
   font-size: 32px;
   /* margin-top: 20px; */
   display: flex;
   justify-content: center;
   align-items: center;
   /* font-family: 'Bebas Neue', cursive; */
    text-transform: uppercase;
    /* text-shadow: 4px 4px 8px #4baeec85; */
    position: relative;
    top: 60px;
  }

  .CourseSwiperContainer {
    height: 750px;
    background-color: white;

  }

  .CourseSwiperContainer .swiper{
padding-top: 60px;
  }

  @media (max-width:1023px) {
    .CourseSwiperContainer {
      height: 750px;
      background-color: white;
  
    }
  }