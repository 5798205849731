* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
  }
  html {
    font-size: 62.5%;
  }
  
  a {
    text-decoration: none;
    color: white;
  }
  
   .toAddListStyleNone ,.shoppingCart , .cart ,.signIn{
    list-style: none;
  }

  /* .toAddListStyleNone ul li{
    display: none;

  } */

.productsMenu{
  position: relative;
}

.rightArrowIcon{
  padding-top: 3px;
  padding-left: 3px;
}
.productsMenu1{
  position: relative;
}
  .productsMenu .dropDownMenu{
    display: none;

  }
  .productsMenu .dropDownMenu2{
    display: none;

  }
  .productsMenu .dropDownMenuCourses{
    display: none;

  }
   .productsMenu .dropDownMenuArtists{
    display: none;

  }
  /* .productsMenu .dropDownMenu3{
    display: none;

  } */
  .productsMenu .dropDownMenu4{
    display: none;
  }

  .productsMenu:hover .dropDownMenu{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: -637px;
    
    width: auto;
    z-index: 100;
  }
  .productsMenu:hover .rightArrowIcon{
    display: none;
  }
  .productsMenu:hover .dropDownMenu2{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 71px;
    left: -790px;
    width: 1539px;
    z-index: 100;
    /* text-decoration: underline; */
  }

  .productsMenu:hover .dropDownMenuCourses{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 72px;
    left: -910px;
    width: 1531px;
    z-index: 100;
  }
  .productsMenu:hover .dropDownMenuArtists{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 71px;
    left: -1026px;
    width: 1519px;
    z-index: 100;
  }
  .listOfDropdownMenuCourses{
    list-style: none;
    padding-left: 20px;
    line-height: 121px;
    font-size: 20px;
  }
  .listOfDropdownMenuArtists{
    list-style: none;
    padding-left: 20px;
    line-height: 104px;
    font-size: 20px;
  }
  .ListOfDropDownMenuServices{
    list-style: none;
    padding-left: 36px;
    line-height: 106px;
   
    font-size: 20px;
    /* text-decoration: underline; */
  }
  .ListOfDropDownMenuServicesEyebrows{
    list-style: none;
    padding-left: 44px;
    line-height: 106px;
    position: relative;
    top: 37px;
  }
  .EyelashExtensionServicesDropDownList{
    list-style: none;
    padding-left: 10px;
    line-height: 104px;
    font-size: 20px;
    /* top: 243px; */
    right: -50px;
    position: relative;
    /* text-decoration: underline; */
  }
  /* .productsMenu:hover .dropDownMenu3{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: -24px;
    width: auto;
    z-index: 100;
  } */

  .productsMenu:hover .dropDownMenu4{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 120%;
    left: -24px;
    width: auto;
    z-index: 100;
  }

  .dropDownMenu4 ul{
    display: flex;
    flex-direction: column;
    background-color: #EFD3D2;
    height: auto;
    width: 140px;
    text-decoration: none;
  }
  
  .dropDownMenu4 ul li{
    padding-top: 20px ;
     text-align: center;
     list-style: none;
     font-family: 'Montserrat',sans-serif;
     font-size: 14px;
     
   }

  .dropDownMenu ul{
    display: flex;
    flex-direction: column;
    background-color:  #EFD3D2;
    height: 333px;
    width: 1519px;
    text-decoration: none;
  }
  .dropDownMenu2 {
    display: flex;
    flex-direction: column;
    background-color:  #67595E;
    height: 525px;
    width: 1540px;
    top: 17px;
    left: -10px;
    position: relative;
    text-decoration: none;
   
  }
  .dropDownMenuCourses{
    display: flex;
    flex-direction: column;
    background-color:  #67595E;
    height: 480px;
    width: 1540px;
    top: 17px;
    left: -10px;
    position: relative;
    text-decoration: none;
  }
  .dropDownMenuArtists{
    display: flex;
    flex-direction: column;
    background-color:  #67595E;
    height: 525px;
    width: 1540px;
    top: 17px;
    left: -10px;
    position: relative;
    text-decoration: none;
  }

.eyebrowsServicePointer{
  cursor: pointer;
  position: relative;
}
  .dropDownMenu3 ul{
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color:  #EFD3D2;
    height: 210px;
    width: 140px;
    text-decoration: none;
  }


  .dropDownMenu ul li{
   padding-top: 20px ;
    text-align: center;
    list-style: none;
    font-family: 'Montserrat',sans-serif;
    font-size: 14px;
    text-align: left;
    padding-left: 20px;
  }
  
  .dropDownMenu3 ul li{
    padding-top: 20px ;
     text-align: center;
     list-style: none;
     font-family: 'Montserrat',sans-serif;
     font-size: 14px;
   }
  
  /* .dropDownMenu2 ul li{
    padding-top: 20px;
     text-align: center;
     list-style: none;
     font-family: 'Montserrat',sans-serif;
     font-size: 14px;
     padding-left: 30px;
   } */
   
  .main-nav{
    width: 100%;
    height: 6.5rem;
    display: grid;
    background-color:  #EFD3D2;
    grid-template-columns: 2rem 1fr 3fr 1fr 1rem;
     /* background-image:linear-gradient(to left bottom, #ca92b5, #be9cce, #a7a8e4, #86b6f1, #5ec3f4, #51c2f2, #41c0f0, #2cbfee, #47b0ee, #669fe7, #828dd9, #987ac4); ;  */
  }

  .logo{
   
    grid-column: 2/3;
    display: flex;
    justify-content: start;
    align-items: center;
    /* margin-right: 6rem; */
    height: 10rem;
    width: 17rem;
    padding-right: 10px;
  }
  .logo:hover .companyNameImg{
    padding-top: 0px;
    transition: all 0.3s ease-out;
    background-color: whitesmoke;
    border-radius: 13px;
    border: 1px  #EFD3D2;
    color: whitesmoke;
    box-shadow: lightskyblue 5px 5px;
    height: 6.5rem;
    text-align: center;
    width: 15rem;
  }

  .logoImg{
    border-radius: 1rem;
    margin-left: 1rem;
  }
  .companyName{
    font-family: 'Anton', sans-serif;
font-family: 'Bebas Neue', cursive;
    margin-left: 1.5rem;
    font-size: 2.5rem;
    font-weight: 400;
    
  }


  .companyNameImg{
    height: 50px;
    width: 150px;
    position: relative;
    bottom: 14px;
  }
  .signIn{
    font-size: 2rem;
    width: 100px;
  }
  .menu-link{
    grid-column: 3/4;

  }
  .menu-link-Link{
    color:#67595E;
    font-size: 14px;
    padding-right: 30px;
    text-shadow: 4px 4px 8px #fbfbfb7d;;
    text-transform: uppercase;
    position: relative;
    bottom: 7px;
    
  }
  .menu-link ul li{
    display: flex;
    align-items: center;
    height: inherit;
  }

  .checkoutCart{
    color:#67595E;
    font-size: 14px;
    text-shadow: 4px 4px 8px #fbfbfb7d;;
    background-color:  #EFD3D2
  }
  .signInHeading{
    color:#67595E;
    font-size: 14px;
    text-shadow: 4px 4px 8px #fbfbfb7d;;

  }
  .menu-link-CompanyName{
    color: black;
    font-size: 2.5rem;
  }

  .menu-link > ul{
    height: 9rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 2rem; */
    font-family: 'Montserrat',sans-serif;
    font-size: 16px;
    padding-left: 170px;
    text-transform: uppercase;
    position: relative;
    bottom: 7px;
    color: white;
  }
  /* .menu-link ul li:hover{
    padding-left: 15px;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: all 0.3s ease-out;
    background-color: rgba(236, 133, 245, 0.884);
    border-radius: 13px;
    border: 1px rgba(236, 133, 245, 0.884);
    color: whitesmoke;
    box-shadow: lightskyblue 5px 5px;
     display: flex;
    justify-content: center;
    align-items: center;
  }  */

  /* .menu-link-Link:hover{
    padding-left: 15px;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: all 0.3s ease-out;
    background-color: rgba(236, 133, 245, 0.884);
    border-radius: 13px;
    border: 1px rgba(236, 133, 245, 0.884);
    color: whitesmoke;
    box-shadow: lightskyblue 5px 5px;
  } */

  .signIn-search-cart{
    grid-column: 4/5;
    padding-left: 150px;
  }

  .signIn-search-cart ul{
    height: 9rem;
    display: grid;
    grid-template-columns: 3fr repeat(3, 1fr);
    align-items: center;
    position: relative;
    bottom: 7px;
    
  }

  .signIn-search-cart ul li{
    
    /* padding-right: 1rem; */
    /* font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive; */
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    padding-left: 20px;
    cursor: pointer;
    color: #67595E;
    text-shadow: 4px 4px 8px #fbfbfb7d;;
    position: relative;
    bottom: 7px;

  }

  .signIn-search-cart ul li:first-of-type{
    
    text-align:right
    
  }

  /* .signIn-search-cart ul li:hover{
    padding: 2px 2px 2px 2px;
    transition: all 0.3s ease-out;
    background-color: rgba(236, 133, 245, 0.884);
    border-radius: 13px;
    border: 1px rgba(236, 133, 245, 0.884);
    color: whitesmoke;
    box-shadow: lightskyblue 5px 5px;
  } */
.cart:hover{
    padding-inline-start: 2px 2px 2px 2px;
}
  .signIn-search-cart ul li:first-child{
    grid-column: 1/2;
  }

  .signIn-search-cart .hamburger-menu{
    display: none;
  }

  .signIn,.shoppingCart{
    text-align: center;
    text-shadow: 4px 4px 8px #2d6ee0;;

  }

  .shoppingCart{
    width:50px;
    text-shadow: 4px 4px 8px #2d6ee0;;
display: flex;
flex-direction: row;

  }
  .EyelashExtensionServicesDropDown{
    position: absolute;
    left: 175px;
    top: 0;
      }
      .eyelashEtensionKeratinLashLiftList{
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
        
       }
       
       .eyelashEtensionKeratinLashLiftList:hover > .toHideKeratinLashLiftContainerForNavbar{
        display: block;
      }
      .eyelashEtensionKeratinLashLiftList:hover{
        text-decoration: underline;
      }
      .toHideKeratinLashLiftContainerForNavbar{
        display: none;
        position: absolute;
        top: 72px;
        left: 20px;
        width: 100%;
        line-height: 20px;
      }
      .classicLashCertificationList{
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
      }
      .ArtistsAnmosuneNList{
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
      }
      .eyelashExtensionPointer{
        cursor: pointer;
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
      }
      .eyelashExtensionPointer:hover .EyelashExtensionServicesDropDown{
        display: block;
        z-index: 10;
        /* margin-left: 20px; */
      }
      .eyelashExtensionPointer:hover{
        text-decoration: underline;
      }
      .ArtistsjackieAList{
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
      }
      .ArtistsAnmosuneNList:hover > .toHideAnnosuneNForNavbar{
        display: block;
      }
      .ArtistsAnmosuneNList:hover {
        text-decoration: underline;
      }
      .ArtistsKatelynNList{
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
      }
      .ArtistsShellyCList{
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
      }
      .ArtistsHappyVList{
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
      }
      .VolumeLashCertificationList{
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
      }
      .LashLiftCertificationList{
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
      }
       .classicLashCertificationList:hover > .toHideClassicLashCertificationForNavbar{
        display: block;
      }
      .classicLashCertificationList:hover{
        text-decoration: underline;
      }
      .ArtistsjackieAList:hover > .toHideJackieAForNavbar{
        display: block;
      }
      .ArtistsjackieAList:hover{
        text-decoration: underline;
      }
      .ArtistsKatelynNList:hover > .toHideKatelynNForNavbar{
        display: block;
      }
      .ArtistsKatelynNList:hover{
        text-decoration: underline;
      }

      .ArtistsShellyCList:hover > .toHideShellyCForNavbar{
        display: block;
      }
      .ArtistsShellyCList:hover{
        text-decoration: underline;
      }
      .ArtistsHappyVList:hover > .toHideHappyVForNavbar{
        display: block;
      }
      .ArtistsHappyVList:hover{
        text-decoration: underline;
      }
      .VolumeLashCertificationList:hover > .toHideVolumeLashCertificationForNavbar{
        display: block;
      }
      .VolumeLashCertificationList:hover {
        text-decoration: underline;
      }
      .LashLiftCertificationList:hover > .toHideLashLiftCertificationForNavbar{
        display: block;
      }
      .LashLiftCertificationList:hover{
        text-decoration: underline;
      }
      .toHideAnnosuneNForNavbar{
        display: none;
        position: absolute;
    top: 69px;
    left: 331px;
    line-height: 20px;
      }
      .toHideShellyCForNavbar{
        display: none;
        position: absolute;
        top: -126px;
        left: 331px;
        line-height: 20px;
      }
      .toHideHappyVForNavbar{
        display: none;
        position: absolute;
    top: -205px;
    left: 331px;
    line-height: 20px;
      }
      .toHideKatelynNForNavbar{
        display: none;
        position: absolute;
        top: -54px;
        left: 331px;
        line-height: 20px;
      }
      .toHideJackieAForNavbar{
        display: none;
        position: absolute;
        top: 0px;
        left: 331px;
        line-height: 20px;
      }
      .toHideVolumeLashCertificationForNavbar{  
         display: none;
         position: absolute;
         top: -153px;
         left: 437px;
         line-height: 20px;
      }
      .toHideClassicLashCertificationForNavbar{
        display: none;
        position: absolute;
        top: 0px;
        left: 331px;
        /* width: 100%; */
        line-height: 20px;
      }
      .toHideLashLiftCertificationForNavbar{
        display: none;
        position: absolute;
        top: -39px;
        left: 459px;
        /* width: 100%; */
        line-height: 20px;
      }
      .eyelashEtensionForMegaVolume{
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
       }
       .eyelashEtensionForMegaVolume:hover > .toHideMegaVolumeContainerForNavbar{
        display: block;
      }
      .toHideMegaVolumeContainerForNavbar{
        display: none;
        position: absolute;
        top: -348px;
        left: 259px;
        width: 100%;
        line-height: 20px;
      }
      .eyelashEtensionForVolume{
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
      }
      .eyelashEtensionForVolume:hover > .toHideVolumeContainerForNavbar{
        display: block;
      }
      .toHideVolumeContainerForNavbar{
        display: none;
        position: absolute;
        top: -233px;
        left: 250px;
        width: 100%;
        line-height: 20px;}

       .eyelashEtensionForClassics{
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
       }
       
        .eyelashEtensionForHybrids{
          list-style: none;
          position: relative;
      font-family: 'Montserrat',sans-serif;
        }
        .eyelashEtensionForHybrids:hover > .toHideHybridsContainerForNavbar{
          display: block;
          
        }
      
      .eyelashEtensionForClassics:hover > .toHideClassicsContainerForNavbar{
        display: block;
        
      }
      .toHideHybridsContainerForNavbar{
        display: none;
        position: absolute;
        top: -75px;
        left: 213px;
        width: 100%;
        line-height: 20px;
      }
      .toHideClassicsContainerForNavbar{
        display: none;
        position: absolute;
        top: 239px;
        left: 98px;
        width: 100%;
        line-height: 20px;
    }
      

      .EyelashExtensionServicesDropDown ul li{
        width: 202px;
        color: white;
      }
      /* .dropDownMenu2 ul ul ul {
        width: 700px;
        height: inherit;
        background-color: black;
      }
      .dropDownMenu2 ul ul{
        width: 190px;
        position: relative;
        top: 0;
        height: auto;
      } */

      .EyelashExtensionServicesDropDown{
        display: none;
      }
      .EyebrowsServicesDropDown{
        display: none;

      }
      .eyebrowsServicePointer:hover .EyebrowsServicesDropDown{
display: block;
position: absolute;
    top: -35px;
    left: 174px;
    margin-left: 20px;
      }
      .eyebrowsServicePointer:hover{
        text-decoration: underline;
      }
      .eyelashExtensionPointer:hover .EyelashExtensionServicesDropDown{
        display: block;
        z-index: 10;
        /* margin-left: 20px; */
      }
      .MicrobladingServiceList{
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
      }
      .MicrobladingServiceList:hover > .toHideMicrobladingContainerForNavbar{
        display: block;
      }
      .MicrobladingServiceList:hover{
        text-decoration: underline;
      }
      .toHideMicrobladingContainerForNavbar{
        display: none;
        position: absolute;
        top: -5px;
        left: 213px;
        width: 100%;
        line-height: 20px;
      }
      .browsBykaetlynServiceList{
     
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;

      }
      .browsBykaetlynServiceList:hover > .toHideBrowsBykatelynContainerForNavbar{
        display: block;
      }
      .browsBykaetlynServiceList:hover{
        text-decoration: underline;
      }
      .toHideBrowsBykatelynContainerForNavbar{
        line-height: 20px;
        display: none;
        position: absolute;
        top: -117px;
        left: 213px;
        width: 100%;
      }
      .WaxingServiceList{
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
      }
      .WaxingServiceList:hover > .toHideWaxingContainerForNavbar{
        display: block;
      }
      .WaxingServiceList:hover {
        text-decoration: underline;
      }
      .toHideWaxingContainerForNavbar{
        display: none;
        position: absolute;
        top: -74px;
        left: 400px;
        /* width: 100%; */
        line-height: 20px;
      }
      .RemovalServiceList{
        list-style: none;
        position: relative;
    font-family: 'Montserrat',sans-serif;
      }
      .RemovalServiceList:hover > .toHideRemovalContainerForNavbar{
        display: block;
      }
      .RemovalServiceList:hover{
        text-decoration: underline;
      }
      .toHideRemovalContainerForNavbar{
        display: none;
        position: absolute;
        top: -210px;
        left: 400px;
        /* width: 100%; */
        line-height: 20px;
      }
      .ourProductsForNavbar{
        display: none;
      }
     .emebellashProductsHover:hover + .ourProductsForNavbar{
display: block;
     }
  @media (max-width: 1080px) {
    .main-nav {
      height: 8rem;
      grid-template-columns: 2rem 3fr 3fr 1fr 2rem;
    }
  
    .logo,
    .menu-link ul,
    .signIn-search-cart ul {
      height: 8rem;
    }
  }

  
  /* responsive css style */
  @media (max-width: 998px) {
    .main-nav {
      height: 7rem;
      grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
    }
  
    .menu-link {
      display: none;
    }


    .logo {
      height: 7rem;
    }
  
    .mobile-menu-link {
      grid-column: 2/4;
      position: relative;
      z-index: 99;
    }
    .mobile-menu-link {
        background-color: #EFD3D2;
        height: 20rem;
        display: grid;
        grid-column: 2/5;
        align-items: center;
        padding-left: 3rem;
        transition: all 2s linear;
        transform-origin: top;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      }
    
      .mobile-menu-link ul {
        height: 20rem;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: start;
      }
    
      .mobile-menu-link ul li:first-child {
        transition-delay: 0.2s;
      }

      .signIn-search-cart {
        grid-row: 1/2;
        grid-column: 3/5;
        justify-items: end;
        align-items: center;
        transition: all 2s linear;
      }
    
      .signIn-search-cart .signIn-search-cart-desktop {
        height: 0;
        display: none;
      }
    
      .signIn-search-cart {
        height: 7rem;
        display: flex;
        justify-self: end;
        align-items: center;
      }
    
      .signIn-search-cart .hamburger-menu {
        display: block;
        font-size: 2.5rem;
      }
    }

    @media (max-width: 798px) {
        .main-nav {
          height: 6rem;
          grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
        }
      
        .logo,
        .signIn-search-cart ul {
          height: 6rem;
        }
      
        .signIn-search-cart {
          height: 6rem;
          display: flex;
          justify-self: end;
          align-items: center;
        }
      
        .signIn-search-cart .hamburger-menu {
          display: block;
          font-size: 2.5rem;
        }
      }

      @media (max-width: 520px) {
        .main-nav {
          height: 6rem;
          grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
        
        }
      
        .logo,
        .signIn-search-cart ul {
          height: 6rem;
        }
      
        .logo h2 {
          font-size: 2rem;
        }
      
        .signIn-search-cart {
          height: 6rem;
          display: flex;
          justify-self: end;
          align-items: center;
        }
      
        .signIn-search-cart .hamburger-menu {
          display: block;
          font-size: 2.5rem;
        }
        .html{
          width: 520px;
        }
    }