* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .bodyOfBrowsBykatelynPage {
 
    background-color:  #EFD3D2;
  }
 
  .microbladPlusShadeThroughNavbarPrice{
    position: relative;
    bottom: 20px;
    font-size: 20px;
    
    font-family: "Montserrat", sans-serif;
  }
  .microbladPlusShadeThroughNavbarPrice-BrowsByKatelyn{
    position: relative;
    bottom: 20px;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    right: 95px;
  }
  .toSetBookNowButtonInBrowsByKatelynPageInHennaEyebrows{
    position: initial;
  }
  .tosetButtonOfEyebrowLamination{
    position: initial;
    position: relative;
    /* right: 114px; */
    left: -49px;
  }
  .microbladPlusShadePageFold1Container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    background-color: white;
  }
  
  .KeratinlashLiftServiceInfoBelowHeadingList {
    list-style-type: circle;
    margin-top: 15px;
    padding-left: 40px;
    padding-bottom: 15px;
    text-decoration: underline;
    line-height: 30px;
    /* color: black; */
  }
  .KeratinlashLiftServiceInfoBelowHeading{
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    

  }
  .KeratinlashLiftServiceHeading-BrowsByKatelyn{
    text-align: center;
    font-size: 28px;
    font-family: "Montserrat", sans-serif;
    position: relative;
    right: 76px;

  }
  .KeratinlashLiftServiceHeading-BrowsByKatelyn-Lamination{
    text-align: center;
    font-size: 28px;
    font-family: "Montserrat", sans-serif;
    position: relative;
    right: -13px;
  }
  .KeratinlashLiftServiceHeading-BrowsByKatelyn1{
    text-align: center;
    font-size: 28px;
    font-family: "Montserrat", sans-serif;
    position: relative;
    right: -91px;
  }
  .bookNowButtonKeratinlashLiftService-BrowsByKateln{
    position: relative;
    right: 50px;
  }
  .KeratinlashLiftServiceInfo-BrowsByKatelyn{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .EyelashExtensionServicePageImg1-BrowsByKatelyn
  {
    height: 500px;
    width: 450px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0 0 0);
    border-radius: 20px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    position: relative;
    left: 160px;
  }
  .EyelashExtensionServicePageImg1-BrowsByKatelyn-Lamination{
    height: 500px;
    width: 450px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0 0 0);
    border-radius: 20px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    position: relative;
    left: -207px;
  }
  .microbladPlusShadeServicePageImg1 {
    height: 500px;
    width: 450px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 10px;
    margin-left: 0px;
  }
  
  .microbladPlusShadeServiceInfo {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 240px;
/* position: relative; */
/* left: 210px; */
    margin-bottom: 10px;
  }
  
  .microbladPlusShadeServiceHeading {
    
    height: 100px;
    font-size: 28px;
    /* font-family: 'Bebas Neue', cursive; */
    font-family: "Montserrat", sans-serif;
    /* background-color: rgb(232, 106, 167); */
    text-shadow: 4px 4px 8px #f5f5f542;
    color: black;
    /* padding-left: 70px; */
    font-weight: 500;
    position: relative;
    top: -20px;
  }
  
  .KeratinlashLiftServiceHeading2 {
    width: 800px;
    height: 100px;
    font-size: 80px;
    /* font-family: 'Bebas Neue', cursive; */
    /* font-family: Retina,Arial,Helvetica,sans-serif; */
    font-family: "Montserrat", sans-serif;
  
    font-weight: 100;
    color: whitesmoke;
    /* text-shadow: 4px 4px 8px #FF0000;; */
    text-shadow: 4px 4px 8px #2d6ee0;
  }
  .microbladPlusShadeInfoBelowHeading {
    /* width: 750px; */
    font-size: 15px;
    /* font-family: 'Bebas Neue', cursive; */
    /* font-family: 'Oswald', sans-serif; */
    /* margin: 10px; */
    /* font-family: Escrow Condensed,Georgia,serif; */
    font-family: "Montserrat", sans-serif;
  
    font-weight: 120;
    /* text-shadow: 4px 4px 8px #2d6ee0;; */
    width: 500px;
    text-align: left;
    padding-right: 00px;
    /* background-color: rgb(232, 106, 167); */
    text-shadow: 4px 4px 8px #f5f5f542;
    color: black;
    /* text-shadow: 4px 4px 8px #FF0000;; */
  
    position: relative;
    top: -70px;
    /* margin-top: 90px; */
  }
  
  .bookNowButtonmicrobladPlusShadeService {
    /* margin-right: 200px; */
  
    position: relative;
    top: -10px;
    left: 120px;
 

  }

  .bookNowButtonOnThemicrobladPlusShadePage{
    height: 50px;
    width:170px;
    border-radius: 5px;
    font-family: 'Anton', sans-serif;
    font-family: 'Bebas Neue', cursive;
    font-size: 20px;
    padding-top: 4px;
    box-shadow: black 5px 5px;
    cursor: pointer;
    border-color: #EFD3D2;
    background-color: #EFD3D2;
    margin-top: 20px;
    color: black;

}

.bookNowButtonOnThemicrobladPlusShadePage:hover{
    padding-top: 4px;
    transition: all 0.3s ease-out;
    /* background-color:whitesmoke; */
    /* background-color: #0b7def; */
    background-color:  #EFD3D2;

    border-radius: 5px;
    border: 1px  #EFD3D2;
    color:whitesmoke;
    
}


  .WaxingServicePageFold2Container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    background-color: white;
    margin-top: 5px;
    
  }

  .WaxingServiceInfo{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 50px;
    position: relative;
    right: 60px;
    margin-bottom: 10px;
  }

  .WaxingServiceHeading{
    width: 100%;
    height: 100px;
    font-size: 28px;
    /* font-family: 'Bebas Neue', cursive; */
    font-family: "Montserrat", sans-serif;
    /* background-color: rgb(232, 106, 167); */
    text-shadow: 4px 4px 8px #f5f5f542;
    color: black;
    /* padding-left: 70px; */
    font-weight: 500;
    position: relative;
    top: -20px;
  }
.browsByKatelynMobileNavbar{
  display: none;
}
  .WaxingServiceInfoBelowHeading{
 
    font-size: 15px;
  
    font-family: "Montserrat", sans-serif;
  
    font-weight: 120;
   
    width: 500px;
    text-align: left;
    padding-right: 00px;
   
    text-shadow: 4px 4px 8px #f5f5f542;
    color: black;
    position: relative;
    top: -70px;
   
  }

  .WaxingServiceInfoBelowHeadingList{
    list-style-type: circle;
    margin-top: 15px;
    padding-left: 40px;
    padding-bottom: 15px;
    text-decoration: underline;
    line-height: 30px;
  }
.WaxingServiceHeading-BrowsByKatelyn{
  width: 100%;
  height: 100px;
  font-size: 28px;
  /* font-family: 'Bebas Neue', cursive; */
  font-family: "Montserrat", sans-serif;
  /* background-color: rgb(232, 106, 167); */
  text-shadow: 4px 4px 8px #f5f5f542;
  color: black;
  /* padding-left: 70px; */
  font-weight: 500;
  position: relative;
  top: -20px;
}


  .bookNowButtonWaxingService{
    position: relative;
    top: -10px;
    left: 120px;
  }

  .WaxingServicePageImg2{
    height: 500px;
    width: 450px;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgb(0, 0, 0);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 10px;
    margin-left: 50px;
  }

  .EyelashExtensionServicePageFold3Container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    background-color: white;
    margin-top: 5px;
  }

  .BrowsBykatelynServicePageHeading{
    text-align: center;
    font-size: 28px;
    color: white;
    background-color: black;
    font-family: "Montserrat", sans-serif;
    height: 70px;
  padding-top: 15px;
    font-weight: 50;
  }
.KeratinlashLiftServiceInfoBelowHeading-BrowsByKatelyn{
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  position: relative;
  left: 98px;
  width: 45%;

}
.KeratinlashLiftServiceInfoBelowHeading-BrowsByKatelyn-Lamination{
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  position: relative;
  right: 7px;
  margin-top: 10px;
}

  @media (max-width:1023px) {
    .EyelashExtensionServicePageHeading{
      height: 100px;
    }

    .microbladPlusShadePageFold1Container{
       display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    padding-top: 25px;
  }

  .BrowsBykatelynServicePageHeading{
    text-align: center;
    position: relative;
    
    /* margin-top: 40px; */
    width: 100%;
  }
    .microbladPlusShadeServicePageImg1{
      height:auto;
    width: 300px; 
    }
    .microbladPlusShadeInfoBelowHeading{
    padding-left: 0px;
   width: 70%;

    }
    .microbladPlusShadeServiceInfo{
      margin-left: 0px;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
    }
    .abovemicrobladPlusShadeServiceInfoBelowHeadingList{
      text-align: left;
      /* margin-left: 40px; */
      width: 100%;
    }
    .KeratinlashLiftServiceInfoBelowHeadingList{
      padding-left: 61px;
      width: 320px;

    }
    .microbladPlusShadeServiceInfoBelowHeadingList{
      padding-left: 8px;
    /* width: 130%; */
    text-align: left;
    margin: 10px;
    line-height: 30px;
    }
    .belowmicrobladPlusShadeServiceInfoBelowHeadingList{
      width: 107%;
      text-align: left;
    }
    .bookNowButtonmicrobladPlusShadeService{
      position: relative;
      top: -50px;
      left: -4px;
    }

    .WaxingServicePageFold2Container{
       display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    height: auto;
    padding-top: 25px;
    }
    .WaxingServicePageImg2{
      height:auto;
    width: 300px; 
    margin-left: 0px;
    }
    .WaxingServiceHeading{
      position: relative;
      top: 0px;
      
      
      text-align: center;
    margin-bottom: 20px;
    }
    .WaxingServiceHeading-BrowsByKatelyn{
text-align:center;
    }
    .WaxingServiceInfoBelowHeading{
      width: 82%;
      text-align: center;
    }
    .WaxingServiceInfoBelowHeadingList{
      padding-left: 17px;
    }
    .WaxingServiceInfo{
      justify-content: center;
      align-items: center;
    margin-right: 0px;
    right: 0;

    }
    .bookNowButtonWaxingService{
      top: -55px;
    left: 0px;
    }
    .EyelashExtensionServicePageFold3Container{
      display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    }
    .responsiveHeadingForClassicLashExtensionService{
      margin-bottom: 10px;
    }
    .responsiveKeratinlashLiftServiceInfoBelowHeading{
      width: 80%;
      padding-left: 1px;
      text-align: center;
    }
    .ResponsiveKeratinlashLiftServiceInfo-BrowsByKatelyn{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .responsiveKeratinlashLiftServiceInfoBelowHeadingList{
      padding-left: 16px;
    }
    .responsiveVolumeLashExtensionServiceContainer{
      padding-top: 25px;
    }
    .responsiveHybridLashExtensionServiceHeading{
      width: 100%;
      left: 84px;
      text-align: center;
      margin-bottom: 54px;
    }
    .responsiveVolumeLashExtensionHeading{
      margin-bottom: 38px;
    }
    .responsiveVolumeLashExtensionBelowHeading{
      width: 93%;
      padding-left: 17px;
      text-align: center;
    }
    .microbladPlusShadeThroughNavbarPrice{
margin-top: 40px;
    }
    .EyelashExtensionServicePageImg1-BrowsByKatelyn{
      height: auto;
      width: 300px;
      left: 0px;
      margin-top: 40px;
    }
    .microbladPlusShadeThroughNavbarPrice-BrowsByKatelyn{
      right: 0px;
      margin-top: 40px;
    }
    .KeratinlashLiftServiceHeading-BrowsByKatelyn{
      right: 0px;
      bottom: 15px;
    }
    .KeratinlashLiftServiceInfoBelowHeading-BrowsByKatelyn{
left: 0px;
width: 86%;
text-align: center;
margin-top: 20px;
    }
    .bookNowButtonKeratinlashLiftService-BrowsByKateln{
      right: 0px;
margin-bottom: 60px;
    }
    .responsiveHybridLashExtensionServiceHeading{
left: 0px;
    }
    .toSetBookNowButtonInBrowsByKatelynPageInHennaEyebrows{
      bottom: 50px;
      position: relative;
    }
    .EyelashExtensionServicePageImg1-BrowsByKatelyn-Lamination{
      height: auto;
      width: 300px;
      left: 0px;
      margin-top: 20px;
    }
    .responsiveVolumeLashExtensionHeading{
      margin-bottom: 20px;
      text-align: center;
    }
    .tosetButtonOfEyebrowLamination{
      left: 2px;
    top: 1px;
    margin-bottom: 60px;

    }
    .KeratinlashLiftServiceHeading-BrowsByKatelyn-Lamination{
      right: 0px;
    }
    .KeratinlashLiftServiceHeading-BrowsByKatelyn1{
      right: 0px;
    }
    .BrowsByKatelynNavbar{
      display: none;
    }
    .browsByKatelynMobileNavbar{
      display: block;
      width: 100%;
      position: static;
      top: 0;
      z-index: 100;
    }
  }
