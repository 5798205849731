.mobileNavbarFlex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:  #EFD3D2;
   

}
.companyNameImgMobileNavbar{
    height: 50px;
    width: auto;
    margin: 10px;
    
}
.main-div{
    background-color:  #EFD3D2;
    padding: 10px;
}



