.checkout{
    display: flex;
    background-color: white;
    flex-direction: column;
    
}
.checkoutMainHeading{
    font-size: 28px;
/* font-family: '', serif; */
font-family: 'Montserrat',sans-serif;
color:white;
background-color: black;
width: 100%;
text-align: center;
height: 80px;
    padding-top: 20px;
}
.checkoutLeftAndright{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.checkoutHeading{
    font-size: 16px;
/* font-family: '', serif; */
font-family: 'Montserrat',sans-serif;
}
.checkoutRight{
    position: sticky;
    top: 0;
    position: relative;

}
.yourSubtotal{
    font-size: 14px;
/* font-family: '', serif; */
font-family: 'Montserrat',sans-serif;
}
@media (max-width:1023px) {
    .checkoutLeftAndright{
        flex-direction: column;
    }
    .checkoutTitle{

    }
    .checkoutHeading{
        text-align: center;
    }
}