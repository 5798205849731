*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#root{
display: flex;
flex-direction: column;

}

 .allThingsContainer{
    background-color: white;
    padding-top: 60px;
    height: 700px;
} 
.mainProductContainer{
    display: flex;
    justify-content: center;
    align-items: center;


}

.headingOurProducts{
   
    /* font-family: Retina,Arial,Helvetica,sans-serif; */
    font-family: 'Montserrat',sans-serif;

    font-weight: 100;
   padding-bottom: 20px;
   color: black; 
   font-size: 32px;
   margin-top: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   /* font-family: 'Bebas Neue', cursive; */
text-transform: uppercase;
/* text-shadow: 4px 4px 8px #4baeec85; */
position: relative;
top: 20px;
}

.AdhesiveNozzlewipes{
margin-right: 100px;
}

.DisposableApplicator{
margin-right: 100px;

}

.FanAddicts{
margin-right: 100px;
    
}

/* .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    width: 1096px;
    height: auto;
  }
   */
  .swiper-slide img {
    /* display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; */
  }

  /* #app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
} */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
    width: 500px;
    height: auto;
  

  
}

.swiper-slide img {
  /* display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; */

}


